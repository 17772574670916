export default {
  ar: {
    instructor: {
      dob: "تاريخ الميلاد",
      email: "البريد الالكتروني",
      mobile: "رقم الاتصال",
      practicalExperience: "الخبرات العملية",
      basicExperience: "الخبرات الأساسية",
      professional_summary: "موجز الخبرات",
      numberOfCourses: "عدد الدورات",
      numberOfStudents: "عدد الطلاب",
      numberOfFollowers:"عدد المتابعين",
      scientificBackground: "الخلفية العلمية",
      name: "مدرب",
      course: "دورة",
      student: "طالب",
      about: "تفاصيل أكثر عن المدرب",
      overview: "لمحة",
      courses: "الدورات",
      fname: {
        label: "الاسم الأول",
        placeholder: "قم بإدخال اسمك الأول",
      },
      lname: {
        label: "الاسم الأخير",
        placeholder: "قم بإدخال اسمك الأخير",
      },
      email: {
        label: "البريد الالكتروني",
        placeholder: "قم بإدخال بريدك الالكتروني ",
      },
      mobile: {
        label: "رقم الهاتف المحمول",
        placeholder: "قم بإدخال رقم هاتفك المحمول  ",
      },
      profile: {
        label: "صورة شخصية",
        placeholder: "قم بإدخال صورة شخصية  ",
      },
      validationFile: {
        label: "ملف توثيقي",
        placeholder: "قم بإدخال ملف توثيقي  ",
      },
      aboutYou: "لمحة عنك",
      sendRequest: "إرسال الطلب",
      browse: "استعراض",
      wantToHelpPeople:
        "تريد مساعدة الآخرين على النمو، التعلم، وتحصيل المزيد في الحياة؟",
      joinAsInstructor: "انضم إلى منصتنا كمدرب!",
      viewAll: "استعراض الجميع",
      visitProfile: "زيارة الملف الشخصي",
      follower:"مُتابع",
      follow:"متابعة",
      unfollow:"إلغاء المتابعة",
    },
  },
  en: {
    instructor: {
      dob: "Date of Birth",
      email: "Email",
      mobile: "Mobile Number",
      practicalExperience: "Practical Experience",
      basicExperience: "Basic Experience",
      professional_summary: "Professional Summary",
      numberOfCourses: "Number of Courses",
      numberOfStudents: "Number of Students",
      scientificBackground: "Scientific Background",
      name: "Instructor",
      course: "Course",
      student: "Student",
      about: "More Details About the Instructor",
      overview: "Overview",
      courses: "Courses",
      fname: {
        label: "First Name",
        placeholder: "Enter your first name",
      },
      lname: {
        label: "Last Name",
        placeholder: "Enter your last name",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email address",
      },
      mobile: {
        label: "Mobile Number",
        placeholder: "Enter your mobile number",
      },
      profile: {
        label: "Profile Picture",
        placeholder: "Upload a profile picture",
      },
      validationFile: {
        label: "Validation File",
        placeholder: "Upload a validation file",
      },
      aboutYou: "About You",
      sendRequest: "Send Request",
      browse: "Browse",
      wantToHelpPeople:
        "Do you want to help others grow, learn, and achieve more in life?",
      joinAsInstructor: "Join our platform as an instructor!",
      viewAll: "View All",
      visitProfile: "Visit Profile",
      follower:"Follower",
      follow:"Follow",
      unfollow:"Un Follow",
    },
  },
};
