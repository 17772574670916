export default {
  ar: {

    chooseTypeOfCourse:"اختر نوع الكورس",
    coursesWelcome: `WLCD أكثر من مجرد منصة تعليمية، مجتمع يلهمك للتعلم.`,
    coursesDescription: `تغطي دوراتنا مجموعة واسعة من المجالات، بما في ذلك التقنية والأعمال والتسويق الرقمي واللغات والصحة والعافية والفن والعديد من المواضيع المختلفة.
    <br />
    سجّل الآن وابدأ رحلة التعلم!`,
    chooseCategory: "اختر تصنيفاً أساسياً",
    choosePriceTarget: "اختر السعر المناسب",
    chooseLanguage: "اختر اللغة",
    free: "مجانية",
    paid: "مدفوعة",
    subcategories: "التصنيفات الفرعية",
    showingResultsOf: "استعراض نتائج",
    showing: "استعراض",
    totalResults: "نتيجة كلية",
    sort: "ترتيب",
    chooseAnother: "اختر نوعاً آخراً",
    chooseSuitableType: "اختر النوع المناسب",
    chooseSuitableLevel: "اختر المستوى المناسب",
    freeCourses: "تعلّم مجاناً",
    paidCourses: "دورات مدفوعة",
    featuredCourses: "دورات مميزة",
    discountedCourses: "دورات ذات خصومات",
    beginner: "مبتدئ",
    intermediate: "متوسط",
    expert: "متقدم",
    search: {
      action: "ابحث",
      placeholder: "ابحث عن الدورات في المنصة....",
    },
    sortAtoZ: "ترتيب أبجدي تصاعدي",
    sortZtoA: "ترتيب أبجدي تنازلي",
    sortRecent: "ترتيب حسب الأحدث ",
    sortOldest: "ترتيب حسب الأقدم ",
    noResultsFound: "لم يتم العثور على نتائج",
  },
  en: {
    coursesWelcome: "Welcome to the WLCD platform!",
    coursesDescription:
      "We aim to provide high-quality courses to learners all around the world. We are pleased to offer you a diverse range of training courses that can be accessed on our platform.<br />Our courses cover a wide range of fields, including technology, business, digital marketing, languages, health and wellness, art, and many other topics. Each training course is carefully designed to be useful, comprehensive, and continuously updated.<br />You can take advantage of the benefits of online learning, such as flexibility in scheduling, 24/7 access to training content, cost savings, and eliminating the need for travel. With the expertise of specialized trainers, you can enjoy an engaging and effective learning experience.<br />You can explore all the available courses on our website and enroll in the courses that suit your needs and level.<br />Don't miss out on the opportunity! Start now and gain the knowledge and skills that will make you stand out in your fields.",
    chooseCategory: "Choose a main category",
    choosePriceTarget: "Choose the appropriate price",
    chooseLanguage: "Choose the language",
    free: "Free",
    paid: "Paid",
    subcategories: "Subcategories",
    showingResultsOf: "Showing results of",
    showing: "Showing",
    totalResults: "Total results",
    sort: "Sort",
    chooseAnother: "Choose another type",
    chooseSuitableType: "Choose Suitable Type",
    chooseSuitableLevel: "Choose Suitable Level",
    freeCourses: "Free Courses",
    paidCourses: "Paid Courses",
    featuredCourses: "Featured Courses",
    discountedCourses: "Discounted Courses",
    beginner: "Beginner",
    intermediate: "Intermediate",
    expert: "Expert",
    search: {
      action: "Search",
      placeholder: "Search for courses on the platform...",
    },
    sortAtoZ: "Sort A to Z",
    sortZtoA: "Sort Z to A",
    sortRecent: "Sort by Most Recent",
    sortOldest: "Sort by Oldest",
    noResultsFound: "No Results Found",

    chooseTypeOfCourse:"choose course type",
  },
};
