import httpClient from "../client/httpClient";
import Vue from "vue";

const state = {};

const mutations = {
};

const actions = {
  sendCourseReport({ commit }, { courseId, reportData }) {
    commit("setLoading", true);
    httpClient
      .post(
        "/course/report",
        { course_id: courseId, title: reportData.title,email:reportData.email,detail:reportData.detail },
      )
      .then((response) => {
        commit("setLoading", false);
        if (response.data?.status == "fail") throw Error(response.data.message);
        Vue.$toast.open({
          message: "Report sent successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "could not send report!",
          type: "error",
        });
        console.error(error);
      });
  },
  addCourseToCart({commit},courseId){
    commit("setLoading", true);
    httpClient
      .post(
        "/addtocart",
        { course_id: courseId},
      )
      .then((response) => {
        commit("setLoading", false);
        commit("setCourseCartStatus", true,{root:true});
        Vue.$toast.open({
          message: "Added to cart successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "could not add to cart!",
          type: "error",
        });
        console.error(error);
      });
  },
  removeCourseFromCart({commit},courseId){
    commit("setLoading", true);
    httpClient
      .post(
        "/remove/cart",
        { course_id: courseId},
      )
      .then((response) => {
        commit("setLoading", false);
        commit("setCourseCartStatus", false,{root:true});
        Vue.$toast.open({
          message: "Removed from cart successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "could not remove from cart!",
          type: "error",
        });
        console.error(error);
      });
  },
  enrollInCourse({commit},courseId){
    commit("setLoading", true);
    httpClient
      .post(
        "/free/enroll",
        { course_id: courseId},
      )
      .then((response) => { 
        commit("setLoading", false);
        commit("setCourseEnrollmentStatus", true,{root:true});
        Vue.$toast.open({
          message: "Enrolled to course successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "could not enroll to course!",
          type: "error",
        });
        console.error(error);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
