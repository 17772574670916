export default {
  ar: {
    footer: {
      followUsOnSocialMedia: "تابعونا على وسائل التواصل الاجتماعي!",
      about: "عنا",
      aboutUs: "تعريف المنصة",
      faqs: "الأسئلة الشائعة",
      contactUs: "اتصل بنا",
      content: "المحتوى",
      instructors: "المدربون",
      categories: "التصنيفات",
      courses: "الدورات",
      support: "الدعم",
      blogs: "المدونة",
      terms_and_policy: "الشروط والأحكام",
      terms: "شروط الاستخدام",
      policy: "سياسة الخصوصية",
    },
  },
  en: {
    footer: {
      followUsOnSocialMedia: "Follow Us on Social Media!",
      about: "About",
      aboutUs: "About Us",
      faqs: "FAQs",
      contactUs: "Contact Us",
      content: "Content",
      instructors: "Instructors",
      categories: "Categories",
      courses: "Courses",
      support: "Support",
      blogs: "Blogs",
      terms_and_policy: "Terms and Privacy Policy",
      terms: "Use Terms",
      policy: "Privacy Policy",
    },
  },
};
