import httpClient from "../client/httpClient";
import Vue from "vue";

const GeneralModule = {
  state: () => ({
    faqs: [],
    introVideo: null,
    contactUsReasons: [],
    termsAndPolicy:null,
  }),
  mutations: {
    setFaqs(state, faqs) {
      state.faqs = faqs;
    },
    setIntroVideo(state, introVideo) {
      state.introVideo = introVideo;
    },
    setContactUsReasons(state, reasons) {
      state.contactUsReasons = reasons;
    },
    setTermsAndPolicy(state,termsPolicy){
      state.termsAndPolicy=termsPolicy;
    },
  },
  actions: {
    async fetchFAQs({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/faq")
        .then((response) => {
          commit("setLoading", false);
          commit("setFaqs", response.data.faq);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchContactUsReasons({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/contactus/reasons")
        .then((response) => {
          commit("setLoading", false);
          commit("setContactUsReasons", response.data.reasons);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchTermsAndPolicy({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/policy")
        .then((response) => {
          commit("setLoading", false);
          commit("setTermsAndPolicy", response.data.terms_policy[0]);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchIntroVideo({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/videoSetting")
        .then((response) => {
          commit("setLoading", false);
          commit("setIntroVideo", response.data.video);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
  },
};
export default GeneralModule;
