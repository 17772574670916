import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
// import Login from '@/views/LogInView.vue';
import store from "../store/index.js";

Vue.use(VueRouter);




const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,

  },
  {
    path: "/faqs",
    name: "faqs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FAQ'sView.vue"),
  },
  {
    path: "/terms-and-policy",
    name: "terms_and_policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsAndPrivacyPolicyView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
  },
  {
    path: "/invoice/:id",
    name: "invoice",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InvoiceView.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotificationsView.vue"),
  },
  {
    path: "/all-categories",
    name: "all_categories",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AllCategoriesView.vue"),
    // meta: { requiresAuth: true },
  },
  {
    path: "/HomeViewVideo",
    name: "HomeView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeViewVideo.vue"),
  },
  {
    path: "/contact-us",
    name: "contact_us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUsView.vue"),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUsView.vue"),
  },
  {
    path: "/blogs",
    name: "BlogView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/blogs/BlogView.vue"),
  },
  {
    path: "/blog/:id",
    name: "BlogDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/blogs/BlogDetails.vue"),
  },
  {
    path: "/instructors",
    name: "InstructorsView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/instructor/InstructorsView.vue"
      ),
  },
  {
    path: "/instructor/:id",
    name: "InstructorProfileView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/instructor/InstructorProfileView.vue"
      ),
  },
  {
    path: "/course-details/:id",
    name: "course-details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/courses/CourseDetails.vue"
      ),
  },
  {
    path: "/bundle/:id",
    name: "bundle-details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/bundles/BundleDetailsView.vue"
      ),
  },
  {
    path: "/course-content/:id",
    name: "course-content",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/courses/CourseContentView.vue"
      ),
  },
  {
    path: "/certificate/:id",
    name: "certificate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/courses/CertificateView.vue"
      ),
  },
  {
    path: "/quiz/:id",
    name: "quiz",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/courses/QuizView.vue"
      ),
  },
  {
    path: "/quiz-report/:id",
    name: "quiz-report",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/courses/QuizReportView.vue"
      ),
  },
  {
    path: "/my-all-courses",
    name: "MyAllCourses",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllMyCourses.vue"
      ),
  },
  {
    path: "/all-discounted-courses",
    name: "AllDiscounted",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllDiscounted.vue"
      ),
  },
  {
    path: "/all-free-courses",
    name: "AllFreeCourses",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllFreeCourses.vue"
      ),
  },
  {
    path: "/all-online-courses",
    name: "AllOnlineCourses",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllOnline.vue"
      ),
  },
  {
    path: "/all-record-courses",
    name: "AllRecord",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllRecord.vue"
      ),
  },
  {
    path: "/all-featured-courses",
    name: "AllFeatured",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllFeatured.vue"
      ),
  },
  {
    path: "/all-recent-courses",
    name: "AllRecent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllRecent.vue"
      ),
  },
  {
    path: "/all-best-selling-courses",
    name: "AllBestSelling",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllBestSelling.vue"
      ),
  },
  {
    path: "/all-bundles",
    name: "AllBundle",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AllCourses/AllBundle.vue"
      ),
  },
  {
    path: "/cart",
    name: "Cart",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CartView.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CheckoutView.vue"),
  },
  {
    path: "/pay-success",
    name: "pay-success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PaySuccess.vue"),
  },
  {
    path: "/login",
    name: "LogInView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LogInView.vue"),
  },
  {
    path: "/password-reset",
    name: "password_reset",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PasswordResetView.vue"),
  },
  {
    path: "/signup",
    name: "SignUpView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignUpView.vue"),
  },
  {
    path: "/join-as-instructor",
    name: "JoinAsInstructor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JoinAsInstructorView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const accessToken = sessionStorage.getItem("accessToken");
    const expiresIn = sessionStorage.getItem("expiresIn");
    const tokenType = sessionStorage.getItem("tokenType");
    const isLoggedIn = !!accessToken && !!expiresIn && !!tokenType;

    if (!isLoggedIn) {
      next("/");
   

    } else {
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime >= expiresIn) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("expiresIn");
        sessionStorage.removeItem("tokenType");
        next("/");
      } 
    }
  } else {
    next();
  }
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});




export default router;
