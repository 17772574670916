export default {
  ar: {
    validation: {
      email: "هذا الحقل يجب أن يكون بريد الكتروني صالح.",
      required: "هذا الحقل مطلوب",
    },
  },
  en: {
    validation: {
      email: "This field must be a valid email.",
      required: "This field is required.",
    },
  },
};
