<template>
  <div class="vue-carousel">
    <Carousel :options="carouselOptions" ref="carousel">
      <slot></slot>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "vue-owl-carousel";
export default {
  name: "VueCarousel",
  props: ["carouselOptions"],
  components: {
    Carousel,
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style scoped>
/* .main-wrapper {
  margin: 40px 0px;
  padding: 0px 20px;
} */
</style>
