<template>
  <b-modal :id="`intro-video`" centered lazy size="xl" scrollable>
    <div class="col-lg-12">
      <div v-if="!previewVideo" class="relative ratio-container black-bg">
        <!--case of showing the intro video-->
        <img
          class="img-fluid ratio-content"
          :src="getPreviewImagePath(introVideo?.image)"
          fluid
          alt="image"
        />
        <div class="absolute-full-center d-flex justify-center items-center">
          <b-btn
            @click="showVideo()"
            class="d-flex justify-center items-center size-60 rounded-full bg-white js-gallery"
          >
            <div class="icon-play text-18"></div>
          </b-btn>
        </div>
      </div>
      <div v-else class="relative ratio-container black-bg media-container">
        <template v-show="!iframeLoaded">
          <!--case of showing the intro video-->
          <img
            class="img-fluid ratio-content"
            :src="getPreviewImagePath(introVideo?.image)"
            fluid
            alt="image"
          />
          <div class="absolute-full-center d-flex justify-center items-center">
            <b-spinner
              label="Loading..."
              style="width: 3rem; height: 3rem"
              variant="light"
              type="grow"
            ></b-spinner>
          </div>
        </template>
        <iframe
          v-show="iframeLoaded"
          class="ratio-content"
          :src="introVideo?.url"
          @load="onIframeLoad"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel, hide }"><div></div> </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      previewVideo: false,
      iframeLoaded: false,
    };
  },
  computed: {
    ...mapState({
      introVideo: (state) => state.GeneralModule.introVideo,
    }),
  },
  mounted() {
    this.fetchIntroVideo()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(["fetchIntroVideo"]),
    showVideo() {
        this.previewVideo = true;
      },
      onIframeLoad() {
        this.iframeLoaded = true;
      },
      getPreviewImagePath(imageFile) {
        return process.env.VUE_APP_BASE_URL + `/images/videosetting/${imageFile}`;
    },
  },
};
</script>

<style scoped>
.ratio-container {
  position: relative;
  padding-top: 56.25%; /* Adjusted padding-top to achieve a 16:9 aspect ratio (9 divided by 16, multiplied by 100) */
}

.black-bg {
  background: rgb(0, 0, 0);
}

.ratio-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.media-container iframe {
  width: 100%;
  height: 100%;
}
</style>
