import httpClient from "../client/httpClient";
import Vue from "vue";
import { EventBus } from "@/main";
const InstructorModule = {
  state: () => ({}),
  mutations: {},
  actions: {
    async submitInstructorJoinRequest({ commit }, requestData) {
      commit("setLoading", true);
      await httpClient
        .post(`/join_us`, requestData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Request sent successfully!",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async followInstructor({ commit }, instructorId) {
      commit("setLoading", true);
      await httpClient
        .post(`/follow`, {user_id:instructorId})
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Followed instructor successfully!",
            type: "success",
          });
          EventBus.$emit("followedInstructor",instructorId);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async unfollowInstructor({ commit }, instructorId) {
      commit("setLoading", true);
      await httpClient
        .post(`/unfollow`, {user_id:instructorId})
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Un Followed instructor successfully!",
            type: "success",
          });
          EventBus.$emit("unfollowedInstructor",instructorId);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },

  },
};
export default InstructorModule;
