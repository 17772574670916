import httpClient from "../client/httpClient";
import Vue from "vue";

const CartModule = {
  state: () => ({
    cart: {},
    manualPaymentMethods: [],
  }),
  mutations: {
    setCart(state, cart) {
      state.cart = cart;
    },
    removeItemFromCart(state, itemId) {
      state.cart.cart.splice(
        state.cart.cart.findIndex((item) => item.courses.id === itemId),
        1
      );
    },
    removeAllItemsFromCart(state) {
      state.cart = {};
    },
    setManualPaymentMethods(state, methods) {
      state.manualPaymentMethods = methods;
    },
    removeBundleFromCart(state, bundleId) {
      state.cart.cart.splice(
        state.cart.cart.findIndex((item) => item.bundle_id === bundleId),
        1
      );
    },
  },
  actions: {
    async fetchCart({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/show/cart")
        .then((response) => {
          commit("setLoading", false);
          commit("setCart", response.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async removeItemFromCart({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .post("/remove/cart", { course_id: courseId })
        .then((response) => {
          commit("setLoading", false);
          commit("removeItemFromCart", courseId);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async addBundleToCart({ commit }, bundleId) {
      commit("setLoading", true);
      await httpClient
        .post("/addtocart/bundle", { bundle_id: bundleId })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async removeBundleFromCart({ commit }, bundleId) {
      commit("setLoading", true);
      await httpClient
        .post("/remove/bundle", { bundle_id: bundleId })
        .then((response) => {
          commit("setLoading", false);
          commit("removeBundleFromCart", bundleId);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async removeAllCartItems({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/remove-all/cart")
        .then((response) => {
          commit("setLoading", false);
          commit("removeAllItemsFromCart");
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async applyCoupon({ commit }, couponCode) {
      commit("setLoading", true);
      await httpClient
        .post("/apply/coupon", { coupon: couponCode })
        .then((response) => {
          commit("setLoading", false);
          commit("addCouponDiscount", response.data.discount_amount);
          Vue.$toast.open({
            message: response?.data?.msg ?? "coupon applied successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async removeCoupon({ commit }, couponId) {
      commit("setLoading", true);
      await httpClient
        .post("/remove/coupon-by-id", { coupon_id: couponId })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async removeAllCoupons({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/remove/coupon")
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchManualPaymentMethods({ commit }) {

      commit("setLoading", true);
      await httpClient
        .get("/manual/payment")
        .then((response) => {
          // let data = response.data.manual_payment
          // console.log(data)
          commit("setLoading", false);
          commit("setManualPaymentMethods", response.data.manual_payment)
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async buyCart({ commit }, orderData) {
      commit("setLoading", true);
      await httpClient
        .post("/pay/store", orderData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async visa({ commit }) {
      console.log("visaaaaaaaaaaaa")
      commit("setLoading", true);
      await httpClient
        .post("/stripe-pay", {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          commit("setLoading", false);
          console.log(response.data.session)
          window.location.href = response.data.session.url
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);

        });

    }
  },
};
export default CartModule;
