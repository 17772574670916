import NavigationTranslations from "./translations/NavigationTranslations";
import AllCategoriesPageTranslations from "./translations/AllCategoriesPageTranslations";
import BlogsPageTranslations from "./translations/BlogsPageTranslations";
import ContactUsPageTranslations from "./translations/ContactUsPageTranslations";
import HomeTranslations from "./translations/HomePageTranslations";
import InstructorsPageTranslations from "./translations/InstructorsPageTranslations";
import LoginPageTranslations from "./translations/LoginPageTranslations";
import SignUpPageTranslations from "./translations/SignUpPageTranslations";
import FooterTranslations from "./translations/FooterTranslations";
import InstructorProfileTranslations from "./translations/InstructorProfileTranslations";
import CourseDetailsTranslations from "./translations/CourseDetailsTranslations";
import CartTranslations from "./translations/CartTranslations";
import CourseContentTranslations from "./translations/CourseContentTranslations";
import ProfileTranslations from "./translations/ProfileTranslations";
import ValidationErrorMessages from "./translations/ValidationErrorMessages";
import MessagesTranslations from "./translations/MessagesTranslations";

export default {
  //NAVIGATION TRANSLATIONS
  ...NavigationTranslations["en"],

  //FOOTER TRANSLATIONS
  ...FooterTranslations["en"],

  //HOME PAGE TRANSLATIONS
  ...HomeTranslations["en"],

  //INSTRUCTORS PAGE TRANSLATIONS
  ...InstructorsPageTranslations["en"],

  //INSTRUCTOR PROFILE PAGE TRANSLATIONS
  ...InstructorProfileTranslations["en"],

  //ABOUT US PAGE TRANSLATIONS
  ...ContactUsPageTranslations["en"],

  //All CATEGORIES PAGE TRANSLATIONS
  ...AllCategoriesPageTranslations["en"],

  //BLOGS PAGE TRANSLATIONS
  ...BlogsPageTranslations["en"],

  //LOGIN PAGE TRANSLATIONS
  ...LoginPageTranslations["en"],

  //SIGN UP PAGE TRANSLATIONS
  ...SignUpPageTranslations["en"],

  //COURSE DETAILS TRANSLATIONS
  ...CourseDetailsTranslations["en"],

  //CART TRANSLATIONS
  ...CartTranslations["en"],

  //COURSE CONTENT TRANSLATIONS
  ...CourseContentTranslations["en"],

  //PROFILE TRANSLATIONS
  ...ProfileTranslations["en"],

  //VALIDATION MESSAGES TRANSLATIONS
  ...ValidationErrorMessages["en"],

  //MESSAGES
  ...MessagesTranslations["en"],
};
