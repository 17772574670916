import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
import ar from "./ar";
import en from "./en";

Vue.use(VueI18n);


export default new VueI18n({
  locale:store.state.selectedLanguage, // set the selected language from Vuex as the default locale
  fallbackLocale: 'en', // set the fallback locale
  messages: {
    ar:ar,
    en:en
  }
});