<template>
    <footer class="footer -type-1 bg-dark-1 -green-links">
        <div class="container">
            <div class="footer-header">
                <div class="row y-gap-20 justify-between items-center">
                    <div class="col-auto">
                        <div class="footer-header__logo">
                            <img src="@/assets/WhiteLogo.svg" width="20%" alt="logo" />
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="footer-header-socials">
                            <div
                                class="footer-header-socials__title text-white"
                            >{{ $t('footer.followUsOnSocialMedia') }}</div>
                            <div class="footer-header-socials__list">
                                <a :href="AboutUs.facebook_link" target="_blank">
                                    <i class="icon-facebook"></i>
                                </a>
                                <!-- <a :href="AboutUs.twitter_link" target="_blank">
                                    <i npmclass="icon-twitter"></i>
                                </a> -->
                                <a :href="AboutUs.instagram_link" target="_blank">
                                    <i class="icon-instagram"></i>
                                </a>
                                <!-- <a :href="AboutUs.linkedin_link" target="_blank">
                                    <i class="icon-linkedin"></i>
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-columns row">
                <div class="col-lg-6 row y-gap-30" style="margin-bottom: 30px">
                    <!-- <div class="col-xl-4 col-lg-4 col-md-4">
                        <div
                            class="text-17 fw-500 text-white uppercase mb-25"
                        >{{ $t("footer.about") }}</div>
                        <div class="d-flex y-gap-10 flex-column">
                            <RouterLink :to="{ name: 'AboutUs' }">{{ $t("footer.aboutUs") }}</RouterLink>
                        </div>
                    </div> -->

                    <div class="col-xl-6 col-lg-6 col-md-4">
                        <!-- <div
                            class="text-17 fw-500 text-white uppercase mb-25"
                        >{{ $t("footer.content") }}</div> -->
                        <div class="d-flex y-gap-10 flex-column">
                            
                            <RouterLink
                                :to="{ name: 'all_categories' }"
                            >{{ $t("footer.courses") }}</RouterLink>
                            <RouterLink :to="{ name: 'AboutUs' }">{{ $t("footer.aboutUs") }}</RouterLink>
                        
                            <RouterLink
                                :to="{ name: 'InstructorsView' }"
                            >{{ $t("footer.instructors") }}</RouterLink>
                            <RouterLink :to="{ name: 'BlogView' }">{{ $t("footer.blogs") }}</RouterLink>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <!-- <div
                            class="text-17 fw-500 text-white uppercase mb-25"
                        >{{ $t("footer.support") }}</div> -->
                        <div class="d-flex y-gap-10 flex-column">
                            <RouterLink :to="{ name: 'faqs' }">{{ $t("footer.faqs") }}</RouterLink>
                            <RouterLink
                                :to="{ name: 'terms_and_policy' }"
                            >{{ $t("footer.terms_and_policy") }}</RouterLink>
                            <RouterLink :to="{ name: 'contact_us' }">{{ $t("footer.contactUs") }}</RouterLink>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-5 app-content-footer" style="padding: 0px 20px">
                    <h2 class="app-content__title">تطبيق WLCD</h2>
                    <p class="app-content__text">{{ $t("installAppsDescription") }}</p>
                    <div class="app-content__buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.peaklink.wlcd">
                            <img src="@/assets/img/app/buttons/1.webp" alt="button" />
                        </a>
                        <a href="#">
                            <img src="@/assets/img/app/buttons/2.webp" alt="button" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="py-30 border-top-light-15">
                <div class="row justify-between items-center y-gap-20">
                    <div class="col-auto">
                        <div
                            class="d-flex items-center h-100 text-white"
                        >© {{ new Date().getFullYear() }} WLCD All Right Reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { RouterLink } from 'vue-router';
import { mapActions, mapState } from 'vuex';

export default {
    name: "Footer",
    props: {
        msg: String,
    },
    computed: {
        ...mapState(["AboutUs"]),
    },
    mounted() {
        if (this.AboutUs.length == 0)
            this.fetchAboutUs();
    },
    methods: {
        ...mapActions(["fetchAboutUs"]),
    },
    components: { RouterLink }
};
</script>

<style scoped>
.tf__footer {
    background-image: url("@/assets/imgs/students.jpg");
}
</style>
