import httpClient from "../client/httpClient";
import Vue from "vue";

const AuthModule = {
  state: () => ({}),
  mutations: {},
  actions: {
    async forgetPassword({ commit }, email) {
      commit("setLoading", true);
      await httpClient
        .post("/forgotpassword", { email: email })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async verifyCode({ commit }, { email, code }) {
      commit("setLoading", true);
      await httpClient
        .post("/verifycode", { email: email, code: code })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async resetPassword({ commit }, { email, password }) {
      commit("setLoading", true);
      await httpClient
        .post("/resetpassword", { email: email, password: password })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
  },
};
export default AuthModule;
