<template>
  <nav
    class="navbar navbar-expand-lg main_menu main-navigation z-index-20"
    :class="{ fixed: isNavigationFixed }"
  >
    <div class="container-fluid">
      <RouterLink class="navbar-brand" to="/">
        <img
          src="../assets/newLogo.svg"
          alt="wlcd"
          class="image-width white"
        />
        <img
          src="../assets/newLogo.svg"
          alt="wlcd"
          class="image-width green_img"
        />
      </RouterLink>
      <template v-if="isLoggedIn">
        <router-link class="nav-link d-none lg:d-block" :to="{ name: 'Cart' }"
          ><font-awesome-icon icon="fa-solid fa-cart-shopping" size="lg"
        /></router-link>
        <router-link class="nav-link d-none lg:d-block" to="/notifications">
          <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
          <label v-if="unreadNotificationsCount > 0" class="badge">
            {{ unreadNotificationsCount }}</label
          >
        </router-link>
      </template>
      <button class="navbar-toggler" type="button" @click="openNav">
        <span class="fa fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/">{{
              $t("nav.home")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'InstructorsView' }">{{
              $t("nav.instructors")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'all_categories' }">{{
              $t("nav.courses")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'BlogView' }">{{
              $t("nav.blogs")
            }}</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'AboutUs' }"
              >{{ $t("nav.aboutUs") }}
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'contact_us' }"
              >{{ $t("nav.contactUs") }}
            </router-link>
          </li>
          <li class="nav-item"></li>
        </ul>
      </div>

      <div class="collapse navbar-collapse">
        <!--Language selector-->
        <ul class="m-auto" v-if="localizationEnabled">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="icon-worldwide text-20 mr-15 ml-15"></i>
              {{ $i18n.locale == "ar" ? "العربية" : "English" }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" @click="changeLocaleAndReload('ar')"
                  >العربية</a
                >
              </li>
              <li>
                <a class="dropdown-item" @click="changeLocaleAndReload('en')"
                  >English</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <!--end of language selector-->
        <ul class="navbar-nav m-auto">
          <li
            class="nav-item"
            v-if="isLoggedIn"
            v-b-tooltip
            :title="$t('nav.cart')"
          >
            <router-link class="nav-link" :to="{ name: 'Cart' }"
              ><font-awesome-icon icon="fa-solid fa-cart-shopping" size="lg"
            /></router-link>
          </li>
          <li
            class="nav-item"
            v-if="isLoggedIn"
            v-b-tooltip
            :title="$t('nav.notifications')"
          >
            <router-link class="nav-link" to="/notifications">
              <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
              <label v-if="unreadNotificationsCount > 0" class="badge">
                {{ unreadNotificationsCount }}</label
              >
            </router-link>
          </li>
          <li
            class="nav-item"
            v-if="isLoggedIn"
            v-b-tooltip
            :title="$t('nav.profile')"
          >
            <router-link class="nav-link" to="/profile"
              ><font-awesome-icon icon="fa-solid fa-user-circle" size="lg"
            /></router-link>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link class="nav-link" :to="{ name: 'LogInView' }">{{
              $t("nav.login")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link class="nav-link" :to="{ name: 'SignUpView' }">{{
              $t("nav.signUp")
            }}</router-link>
          </li>
          <li class="nav-item"></li>
          <li class="nav-item" v-if="isLoggedIn">
            <button class="nav-link btn-out" @click="logout">
              {{ $t("nav.logout") }}
            </button>
          </li>
        </ul>
      </div>

      <div id="mySidenav" :style="{ width: sidenavWidth }" class="sidenav">
        <div class="inside-heghit">
          <a href="javascript:void(0)" class="closebtn" @click="closeNav"
            >&times;</a
          >
          <!--Language selector-->
          <ul class="m-auto" v-if="localizationEnabled">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-worldwide text-20 mr-15 ml-15"></i>
                {{ $i18n.locale == "ar" ? "العربية" : "English" }}
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" @click="changeLocaleAndReload('ar')"
                    >العربية</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="changeLocaleAndReload('en')"
                    >English</a
                  >
                </li>
              </ul>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/">{{
                $t("nav.home")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'InstructorsView' }">{{
                $t("nav.instructors")
              }}</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AboutUs' }">{{
                $t("nav.aboutUs")
              }}</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'all_categories' }">{{
                $t("nav.courses")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'BlogView' }">{{
                $t("nav.blogs")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'contact_us' }">{{
                $t("nav.contactUs")
              }}</router-link>
            </li>

            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'LogInView' }">{{
                $t("nav.login")
              }}</router-link>
            </li>
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" :to="{ name: 'SignUpView' }">{{
                $t("nav.signUp")
              }}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" to="/profile">
                {{ $t("nav.profile") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <button class="nav-link btn-out" @click="logout">
                {{ $t("nav.logout") }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { RouterLink } from "vue-router";
import { mapActions, mapState } from "vuex";
import store from '../store/index';

export default {
  data() {
    return {
      isNavigationFixed: false,
      sidenavWidth: "0",
    };
  },
  watch: {
    "$store.state.selectedLanguage"(newLanguage) {
      this.$i18n.locale = newLanguage; // Update the VueI18n instance's locale
    },
  },
  methods: {
    changeLocaleAndReload(newLocale) {
      this.changeLocale(newLocale);
      this.$router.go();
    },
    ...mapActions([
      "SET_LOGGED_IN",
      "logout",
      "changeLocale",
      "fetchUnreadNotificationsCount",
    ]),
    toggleNavigation() {
      const offset = 300;
      if (window.scrollY >= offset) {
        this.isNavigationFixed = true;
      } else {
        this.isNavigationFixed = false;
      }
    },
    openNav() {
      this.sidenavWidth = "250px";
    },
    closeNav() {
      this.sidenavWidth = "0";
    },
  },

  mounted() {
    window.addEventListener("scroll", this.toggleNavigation);
    if (this.isLoggedIn && sessionStorage.getItem("accessToken")) {
      this.fetchUnreadNotificationsCount()
        .then()
        .catch((err) => console.error(err));
    }  },
  computed: {
    ...mapState(["isLoggedIn"]),
    ...mapState({
      unreadNotificationsCount: (state) =>
        state.ProfileModule.unreadNotificationsCount,
    }),
    localizationEnabled() {
      return process.env.VUE_APP_LOCALIZATION_ENABLED == "true";
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleNavigation);
  },
  components: { RouterLink },
};
</script>

<style scoped>
.badge {
  margin-right: -5px;
  margin-bottom: -4px;
  display: inline-block;
  position: relative;
  z-index: 5;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  background-color: #007b4e;
  color: white;
}
.z-index-20 {
  z-index: 25 !important;
}
.image-width {
  width: 50px !important;
}
@media (max-width: 380px) {
  .image-width{
    max-width: 130px;
  }
}
.sidenav {
  height: fit-content !important;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
/* .navbar-nav {
  height: fit-content !important;
} */
.inside-heghit {
  height: 600px !important;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: #ffffff73;
  backdrop-filter: blur(15px);
  padding-bottom: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  animation: main_fixed 0.7s ease-in;
  max-width: 100% !important;
  overflow-x: visible !important;
}
</style>
