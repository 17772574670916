export default {
  ar: {
    login: "تسجيل الدخول",
    doNotHaveAnAccount: `ليس لديك حساب؟`,
    signUpForFree: `قم بإنشاء حساب مجاناً الآن!`,
    loginForm: {
      email: {
        label: "البريد الالكتروني",
        placeholder: "أدخل بريدك الالكتروني...",
      },
      password: { label: "كلمة السر ", placeholder: "أدخل كلمة السر..." },
      loginAction: "تسجيل الدخول",
      generalErrorMessage:
        "فشلت عملية تسجيل الدخول، الرجاء التحقق من البيانات المدخلة!",
    },
    forgotPassword: "هل نسيت كلمة المرور؟",
    passwordReset: "إعادة تعيين كلمة المرور",
    sendVerificationCode: "إرسال رمز إعادة التعيين",
    inputSentVerificationCode: "أدخل رمز التحقق المرسل إلى",
    code: "رمز التحقق",
    verifyCode: "التحقق من الرمز",
  },
  en: {
    login: "Log In",
    doNotHaveAnAccount: "Don't have an account?",
    signUpForFree: "Sign up for free now!",
    loginForm: {
      email: {
        label: "Email",
        placeholder: "Enter your email...",
      },
      password: {
        label: "Password",
        placeholder: "Enter your password...",
      },
      loginAction: "Log In",
      generalErrorMessage: "Login failed, please check the entered data!",
    },
    forgotPassword: "Forgot password?",
    passwordReset: "Password Reset",
    sendVerificationCode: "Send Verification Code",
    inputSentVerificationCode: "Enter the verification code sent to",
    code: "Code",
    verifyCode: "Verify Code",
  },
};
