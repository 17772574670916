import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BCollapse } from 'bootstrap-vue'
import i18n from "./lang/i18n";
import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';
Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin);

Vue.component('b-collapse', BCollapse)
store.dispatch("initializeStore");
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL+"/login";
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});
import GlobalFilters from "@/filters";
//registering global filters
Vue.use(GlobalFilters);
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import "@/assets/css/vendors.css"
// import "@/assets/css/main.css"
// import VueOwlCarousel from "vue-owl-carousel2";
// Vue.component("vue-owl-carousel", VueOwlCarousel);
// require('@/assets/css/all.min.css')
// require("@/assets/css/base.min.css");
// require("@/assets/css/vendors.css");
// require("@/assets/css/main.css");
// require("@/assets/css/style.css");
// require("@/assets/css/responsive.css");
// require('@/assets/sass/abstracts/_spacing.scss')
// require('@/assets/sass/abstracts/_tools.scss')
// require('@/assets/sass/abstracts/_utilities.scss')
// require('@/assets/sass/abstracts/_variables.scss')
// require('@/assets/sass/animations/animations.scss')
// require('@/assets/sass/animations/core.scss')
// require('@/assets/sass/animations/easings.scss')
// require('@/assets/sass/base/_base.scss')
// require('@/assets/sass/base/easings.scss')
// require('@/assets/sass/base/_button.scss')
// require('@/assets/sass/base/_form.scss')
// require('@/assets/sass/base/_typography.scss')
// require('@/assets/sass/base/shame.scss')
// require('@/assets/sass/components/shame.scss')
// require('@/assets/sass/components/shame.scss')
// require('@/assets/sass/components/shame.scss')
// require('@/assets/sass/components/shame.scss')
// require('@/assets/sass/components/shame.scss')

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Plugin } from "vue-responsive-video-background-player";


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
// Import the icon(s) you want to use
import {
  faBook,
  faCircleCheck,
  faLocationDot,
  faClock,
  faChildren,
  faPeopleLine,
  faArrowRight,
  faPlay,
  faChalkboardUser,
  faHeart,
  faCartShopping,
  faUserCircle,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

// Add the icon(s) to the library
library.add(
  faBook,
  faCircleCheck,
  faLocationDot,
  faClock,
  faChildren,
  faPeopleLine,
  faArrowRight,
  faPlay,
  faChalkboardUser,
  faHeart,
  faCartShopping,
  faUserCircle,
  faBell,
);

// Register the component globally
Vue.component("font-awesome-icon", FontAwesomeIcon);
// import './sass/variables.scss'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
AOS.init();
Vue.config.productionTip = false;

//creating event bus
export const EventBus = new Vue();

Vue.use(Plugin);
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
 
}).$mount("#app");

import "@/assets/css/vendors.css"
import "@/assets/css/main.css"