<template>
  <div class="recent-blog">
    <section class="section-bg layout-pt-lg layout-pb-md">
      <div class="section-bg__item -full -height-half bg-dark-5"></div>

      <div class="container pb-10">
        <div class="row justify-center text-center">
          <div class="col-auto">
            <div class="sectionTitle">
              <h2 class="sectionTitle__title text-white">
                {{ $t("recentBlogs") }}
              </h2>
              <p
                class="sectionTitle__text text-white"
                v-html="$t('recentBlogsDescription')"
              ></p>
            </div>
          </div>
        </div>
        <div class="row justify-between pt-20">
          <carousel
            v-if="blogs.length > 0"
            :responsive="responsive"
            class="mt-20 mb-20"
            :rtl="$i18n.locale == 'ar' ? true : false"
            :autoplay="true"
            :slidesToShow="3"
            :infinite="true"
            :slidesToScroll="1"
            :focusOnSelect="false"
            :speed="500"
            :autoplaySpeed="2000"
            :touchMove="false"
          >
            <div v-for="blog in blogs" :key="blog.id" class="p-2">
              <RouterLink
                :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                class="coursesCard -type-1 rounded-8 shadow-3 bg-white ml-20 "
                id="card"
              >
                <div class="relative">
                  <div
                    class="coursesCard__image overflow-hidden rounded-top-8 img-container"
                  >
                    <img
                      class="w-1/1"
                      :src="getRecentBlogImageUrl(blog.image)"
                      alt="image"
                    />
                  </div>
                </div>

                <div class="h-100 pt-5">
                  <div class="head h-50 lh-15 fw-600 text-green-1 mt-10">
                  {{ blog.heading }}
                  </div>

                  <!-- <div class="d-flex x-gap-10 items-center pt-10">
                      <div class="d-flex items-center">
                        <div class="ml-8 mr-8">
                          <div class="icon-clock-2"></div>
                        </div>
                        <div
                        class="text-14 lh-1"
                        >{{ blog.created_at.slice(0, 10) }}</div>
                      </div>
                    </div> -->
                </div>
              </RouterLink>
            </div>
          </carousel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapState, mapActions } from "vuex";
export default {
  name: "RecentBlog",
  data() {
    return {};
  },
  components: {
    carousel,
  },
  computed: {
    ...mapState(["blogs"]),
    responsive() {
      return [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 998,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchBlogs"]),
    getRecentBlogImageUrl(image) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/blog/";
      return baseUrl + image;
    },
    getUserImageUrl(user_img) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/user_img/";
      return baseUrl + user_img;
    },
    goToCourseDetails() {
      this.$router.push({ name: "BlogView" });
    },
  },
  created() {
    this.fetchBlogs(); // automatically fetch products when the component is created
  },
};
</script>

<style>
.object-cover {
}
.coursesCard.-type-1 .coursesCard__image img {
  object-fit: inherit !important;
}
.head {
  font-size: 15px !important;
  text-align: center;
  margin-top: 15px;
}
.ltr {
  direction: ltr !important;
}

.recent-blog .slick-prev:before,
.recent-blog .slick-next:before {
  color: #fff !important;
}
</style>
