export default {
  ar: {
    nav: {
      home: "الصفحة الرئيسية",
      instructors: "المدربون",
      courses: "الدورات",
      blogs: "المدونة",
      aboutUs: "من نحن",
      contactUs: "اتصل بنا",
      cart: "السلة الشرائية",
      profile: "الملف الشخصي",
      notifications: "الإشعارات",
      login: "تسجيل الدخول",
      signUp: "إنشاء حساب",
      logout: "تسجيل الخروج",
    },
  },
  en: {
    nav: {
      home: "Home",
      instructors: "Instructors",
      courses: "Courses",
      blogs: "Blogs",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      cart: "Cart",
      profile: "Profile",
      notifications: "Notifications",
      login: "Log In",
      signUp: "Sign Up",
      logout: "Log Out",
    },
  },
};
