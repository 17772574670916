<template>
    <section class="layout-pt-md layout-pb-md">
        <div class="container">
            <div class="row justify-center text-center">
                <div class="col-auto">
                    <div class="sectionTitle">
                        <h2 class="sectionTitle__title">{{ $t('topCategories') }}</h2>
                        <p class="sectionTitle__text" v-html="$t('categoriesDescription')"></p>
                    </div>
                </div>
            </div>

            <div class="pt-50 js-section-slider">
                <!-- <carousel
                    dir="ltr"
                    easing="linear"
                    :paginationEnabled="false"
                    :loop="true"
                    :autoplay="true"
                    :autoplayTimeout="4000"
                    :scrollPerPage="false"
                    :navigationEnabled="true"
                    :perPage="5"
                >
                    <slide v-for="(category) in categories" :key="category.id">
                        <div class="featureCard -type-1 -featureCard-hover">
                            <div class="featureCard__content">
                                <div class="featureCard__icon">
                                    <img :src="getCatImageUrl(category.cat_image)" alt="icon" />
                                </div>
                                <div class="featureCard__title">{{ category.title }}</div>
                                <div
                                    class="featureCard__text"
                                >{{ category.courses_count }} {{ $t('course.course') }}</div>
                            </div>
                        </div>
                    </slide>
                </carousel>-->
                <carousel
                    v-if="categories.length > 0"
                    :responsive="responsive"
                    class="mt-20 mb-20"
                    :infinite="true"
                    :slidesToScroll="2"
                    :focusOnSelect="false"
                    :speed="500"
                    :autoplay="true"
                    :autoplaySpeed="2000"
                    :touchMove="false"
                >
                    <RouterLink
                        class="swiper-wrapper d-flex justify-center mb-30"
                        v-for="category in categories"
                        :key="category.id"
                        :to="{
                            name: 'all_categories',
                            query: { category_id: category.id },
                        }"
                    >
                        <div class="featureCard -type-1 -featureCard-hover">
                            <div class="featureCard__content">
                                <div class="featureCard__icon">
                                    <img :src="getCatImageUrl(category.cat_image)" alt="icon" />
                                </div>
                                <div class="featureCard__title">{{ category.title }}</div>
                                <div
                                    class="featureCard__text"
                                >{{ category.courses_count }} {{ $t('course.course') }}</div>
                            </div>
                        </div>
                    </RouterLink>
                </carousel>
            </div>
        </div>
    </section>
</template>

<script>
import carousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapState, mapActions } from "vuex";
import { RouterLink } from "vue-router";
export default {
    name: "HomeView",
    components: {
        carousel,
        RouterLink,
    },

    computed: {
        ...mapState(["categories"]), // map the 'products' state to a local computed property
        responsive() {
            return [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 6,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    },
                },
                {
                    breakpoint: 998,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 0,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ];
        },
    },
    methods: {
        ...mapActions(["fetchCategories"]),
        getCatImageUrl(cat_image) {
            const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/category/";
            return baseUrl + cat_image;
        },
    },
    created() {
        this.fetchCategories(); // automatically fetch products when the component is created
    },
};
</script>

<style>
.slick-prev:before,
.slick-next:before {
    color: #007b4e !important;
}
</style>