export default {
  ar: {
    contactUs: "تواصلوا معنا",
    keepInTouch: "رضاك هو هدفنا الأول!",
    contactUsIntro: `إذا كان لديكم أي استفسارات أو مشاكل تتعلق بالدورات التدريبية أو الدفع أو الحساب الشخصي، أو أي موضوع آخر، يمكنكم التواصل مع فريق دعم العملاء لدينا عبر البريد الإلكتروني أو نموذج الاتصال أدناه.`,
    sendAMessage: "أرسل رسالة",
    sendAMessageForm: {
      name: {
        label: "الاسم",
        placeholder: "أدخل الاسم...",
      },
      email: {
        label: "البريد الالكتروني",
        placeholder: "أدخل البريد الالكتروني...",
      },
      mobileNumber: {
        label: "رقم الهاتف",
        placeholder: "أدخل رقم الهاتف...",
      },
      message: {
        label: "الرسالة",
        placeholder: "أدخل الرسالة.....",
      },
      contactReason: {
        label: "سبب التواصل",
        placeholder: "اختر سبب التواصل",
      },
      sendAction: "أرسل",
    },
  },
  en: {
    contactUs: "Contact Us",
    keepInTouch: "Stay in Touch",
    contactUsIntro: `We believe in the importance of communication with our visitors and learners, and we strive to provide excellent customer service and an effective user experience to ensure your satisfaction with our educational platform.
      We are delighted to receive your questions and inquiries, and we welcome sharing your experiences with us.
      <br>
      If you have any questions or issues regarding training courses, payment, your personal account, or any other topic, you can contact our customer support team via email or the contact form below.`,
    sendAMessage: "Send a Message",
    sendAMessageForm: {
      name: {
        label: "Name",
        placeholder: "Enter your name...",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email...",
      },
      mobileNumber: {
        label: "Mobile Number",
        placeholder: "Enter your mobile number...",
      },
      message: {
        label: "Message",
        placeholder: "Enter your message...",
      },
      contactReason: {
        label: "Contact Reason",
        placeholder: "Choose Contact Reason",
      },
      sendAction: "Send",
    },
  },
};
