<template>
    <carousel
        class="mt-20 mb-20"
        :rtl="$i18n.locale == 'ar' ? true : false"
        :infinite="true"
        :slidesToScroll="1"
        :focusOnSelect="false"
        :speed="500"
        :autoplay="true"
        :autoplaySpeed="3000"
        :touchMove="false"
        :responsive="responsive"
        :slidesToShow="3"
        v-on="$listeners"
        v-bind="$attrs"
    >
        <CourseCard v-for="course in courses" :key="course.id" :course="course" />
    </carousel>
</template>

<script>
import CourseCard from "./CourseCard.vue";
import carousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    props: {
        courses: Array,
        responsive: {
            type: Array,
            default: function () {
                return [
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 998,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                    {
                        breakpoint: 0,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ];
            },
        },
    },
    components: {
        CourseCard,
        carousel,
    },
};
</script>

<style scoped>
.ltr {
    direction: ltr !important;
}
</style>
