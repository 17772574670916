<template>
  <div class="bundle">
    <div class="relative my-4">
      <div class="col-lg-auto my-2 justify-view">
        <RouterLink :to="{ name: 'AllBundle'}"  class="button -icon -purple-3 text-purple-1">
          {{ $t('browseAll') }}
          <i class="icon-arrow-top-right text-13 mr-10 ml-10"></i>
        </RouterLink>
      </div>
      <carousel
        v-if="bundle.length > 0"
        :responsive="responsive"
          class="mt-20 mb-20"
          :rtl="$i18n.locale == 'ar' ? true : false"
          :autoplay="true"
          :slidesToShow="3"
          :arrows="true"
          :dots="true"
          :nav="true"
          :center="false"
      >
        <RouterLink
          v-for="bundle in bundle"
          :key="bundle.id"
          class="coursesCard -type-1"
          :to="{ name: 'bundle-details', params: { id: bundle.id } }"
        >
          <div class="relative">
            <div class="coursesCard__image overflow-hidden rounded-top-8">
              <img
                class="w-1/1"
                :src="getCourseImageUrl(bundle.preview_image)"
                alt="image"
              />
              <div class="coursesCard__image_overlay rounded-top-8"></div>
            </div>
            <div
              class="d-flex justify-between py-10 px-10 absolute-full-center z-3"
            ></div>
          </div>

          <div class="h-100 pt-15 pb-10 px-20">
            <div class="course-title text-17 lh-15 fw-500 text-dark-1 mt-10">
              {{ bundle.title }}
            </div>
            <div class=" text-17 lh-15 fw-500 text-dark-1 ">
              {{ bundle.detail }}
            </div>

            <div class="coursesCard-footer">
              <div class="coursesCard-footer__author">
                <img
                  class="ml-10 mr-10"
                  :src="getUserImageUrl(bundle.user_image)"
                  alt="image"
                />
                <div>
                  {{ bundle.user }}
                </div>
              </div>

              <div class="d-flex justify-between items-center">
                <template v-if="bundle?.price">
                  <div
                    v-if="bundle?.discount_price"
                    class="text-24 lh-1 text-dark-1 fw-500"
                  >
                    {{ bundle?.discount_price }} $
                  </div>
                  <div
                    :class="[
                      'lh-1',
                      bundle?.discount_price ? 'line-through' : '',
                    ]"
                  >
                    {{ bundle?.price }} $
                  </div>
                </template>
                <template v-else>
                  <div class="text-24 lh-1 text-dark-1 fw-500">
                    {{ $t("course.free") }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </RouterLink>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { mapState, mapActions } from "vuex";
import { RouterLink } from "vue-router";

export default {
  name: "BundleCourses",
  props: {
    msg: String,
  },
  components: {
    carousel,
    RouterLink,
  },
  computed: {
    ...mapState(["bundle"]), // map the 'products' state to a local computed property
    responsive() {
      return [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 998,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchBundleCourses"]),
    getCourseImageUrl(preview_image) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/bundle/";
      return baseUrl + preview_image;
    },
    getUserImageUrl(user_img) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/user_img/";
      return baseUrl + user_img;
    },
  },
  created() {
    this.fetchBundleCourses(); // automatically fetch products when the component is created
  },
};
</script>
