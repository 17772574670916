export default {
  ar: {
    blogs: "تعلّم من تجارب الآخرين وكن مصدر إلهام للجميع!",
    blogsIntro: `هذه المساحة هي المكان الذي نقدم فيه المقالات والمحتوى الثري والملهم حول مواضيع تعليمية متنوعة. ستجد أيضًا قصص نجاح حقيقية لأشخاص حققوا أهدافهم من خلال دوراتنا.`,
    recentBlogs: "أحدث المقالات",
  },
  en: {
    blogs: "Blog",
    blogsIntro:
      "This space is where we provide articles and rich & inspiring content on various educational topics. Through our blog, we strive to offer valuable content that helps students and learners explore a new world of knowledge and develop their skills.<br />We aim to provide up-to-date and outstanding articles covering a wide range of subjects. Our blog is an interactive space where you can comment and participate in discussions on the topics presented. We believe in the importance of communication and interaction among members of our educational community to exchange experiences, opinions, and ideas.<br />Through our blog, you will stay informed about the latest updates and news related to the platform and the new training courses we offer. You will also find success stories of learners who have benefited from our courses and achieved professional and personal success.",
    recentBlogs: "Recent Posts",
  },
};
