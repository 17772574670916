<template>
  <section class="layout-pt-lg layout-pb-lg">
    <div class="container">
      <div class="row y-gap-20 justify-center text-center">
        <div class="col-auto">
          <div class="sectionTitle">
            <h2 class="sectionTitle__title">{{ $t('testimonials') }}</h2>
          </div>
        </div>
      </div>

      <div class="row justify-center pt-60">
        <div class="col-xl-6 col-lg-8 col-md-10">
          <div class="overflow-hidden js-testimonials-slider">
            <carousel
              v-if="testimonials.length > 0"
              class="ltr"
              :autoplay="true"
              :nav="false"
              :items="1"
              :center="true"
            >
              <div
                class="justify-content-center"
                v-for="testimonial in testimonials"
                :key="testimonial.id"
              >
                <div style="text-align: -webkit-center">
                  <div class="testimonial-pic quote-left radius">
                    <img
                      class=""
                      :src="getTestimonialImageUrl(testimonial.image)"
                      width="100"
                      height="100"
                      alt=""
                      style="width: 90px; height: 90px; margin-bottom: 20px"
                    />
                  </div>
                  <div class="testimonial-text">
                    <p>
                      {{ testimonial.details }}
                    </p>
                    <br />
                    <strong class="testimonial-name"
                      >{{ testimonial.client_name }}
                      <label v-if="testimonial.designation"
                        >( {{ testimonial.designation }} )</label
                      ></strong
                    >
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapState, mapActions } from "vuex";
export default {
  name: "TestimonialComponent",
  components: {
    carousel,
  },

  computed: {
    ...mapState(["testimonials"]),
  },
  mounted() {
    this.fetchTestimonials();
  },
  methods: {
    ...mapActions(["fetchTestimonials"]),
    getTestimonialImageUrl(image) {
      const baseUrl =
        process.env.VUE_APP_BASE_URL + "/public/images/testimonial/";
      return baseUrl + image;
    },
  },
};
</script>

<style>
.ltr {
  direction: ltr !important;
}
.testimonial-pic {
  box-shadow: 0 0 0 2px #e6e6e6;
  width: 70px;
  height: 70px;
}
@media only screen and (max-width: 480px) {
  .testimonial-pic {
    height: 80px;
    width: 80px;
  }
}

.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 5px solid #fff;
}

.quote-left {
  position: relative;
}

.quote-left:before {
  width: 36px;
  height: 36px;
  line-height: 60px;
  font-size: 16px;
  background-color: #007b4e;
  color: #fff;
  text-align: center;
  left: 0;
  top: 85px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.quote-left:before {
  content: open-quote;
  font-size: 60px;
  left: 0;
}
.quote-left:before {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 10;
  top: 65px;
}
.owl-dots-style .owl-dots .owl-dot.active span {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-width: 2px;
}

.owl-dots-style .owl-dots .owl-dot.active span,
.header-nav .nav > li > a.active,
#myNavbar li.active a {
  border-color: #e52d27;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-1 .testimonial-position {
  color: #efbb20;
}
.testimonial-1 {
  text-align: center;
}
.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position,
.testimonial-name,
.testimonial-position {
  display: block;
}
.testimonial-bg {
  color: #fff;
}
.testimonial-text {
  padding: 15px;
  position: relative;
}
</style>
