import httpClient from "../client/httpClient";
import Vue from "vue";

const CourseFilterModule = {
  state: () => ({
    filteredCourses: [],
    languages: [],
  }),
  mutations: {
    setFilteredCourses(state, courses) {
      state.filteredCourses = courses;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
  },
  actions: {
    async fetchFilteredCourses({ commit }, params) {
      commit("setLoading", true);
      await httpClient
        .get("/course/filter", { params: { ...params } })
        .then((response) => {
          commit("setLoading", false);
          commit("setFilteredCourses", response.data.filteredcourses);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchLanguages({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/course/languages")
        .then((response) => {
          commit("setLoading", false);
          commit("setLanguages", response.data.languages);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
  },
};
export default CourseFilterModule;
