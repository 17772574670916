<template>
  <div class="home">
    <HomeNavBarComponent />
    <div class="main-content">
      <div class="content-wrapper js-content-wrapper">
        <section class="masthead -type-6">
          <div class="masthead__bg">
            <img src="../assets/img/home-7/hero/1.svg" alt="blob" />
            <img src="../assets/img/home-7/hero/2.svg" alt="blob" />
            <img src="../assets/img/home-7/hero/bg.png" alt="background" />

            <div
              class="absolute-full-center"
              id="js-masthead-1-particles"
            ></div>
          </div>

          <div class="container mt-60">
            <div class="row y-gap-50 items-center">
              <div class="col-lg-6">
                <div class="masthead__content">
                  <h1
                    class="masthead__title"
                    v-html="$t('heroSectionTitle')"
                  ></h1>
                  <p class="mt-5">
                    {{ $t("heroSection") }}

                    <br class="lg:d-none" />
                  </p>
                  <!--  -->
                  <div>
                    <!-- Check if accessToken is not present in sessionStorage -->
                    <div
                      v-if="!isLoggedIn"
                      class="row items-center x-gap-20 y-gap-20 pt-20"
                    >
                      <div class="col-auto">
                        <RouterLink
                          :to="{ name: 'LogInView' }"
                          class="button -md -gradient-1 -rounded text-white"
                        >
                          {{ $t("joinUs") }}
                        </RouterLink>
                      </div>
                      <div class="col-auto">
                        <RouterLink
                          :to="{ name: 'all_categories' }"
                          class="button -md -outline-light-5 -rounded text-dark-1"
                        >
                          {{ $t("educationalCourses") }}
                        </RouterLink>
                      </div>
                    </div>

                    <!-- Show this content if accessToken is present in sessionStorage -->
                    <div
                      v-else
                      class="row items-center x-gap-20 y-gap-20 pt-20"
                    >
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          justify-content: center;
                        "
                      >
                        <b-button
                          v-b-modal.modal-1
                          class="button -dark-1 text-white mb-2"
                          id="btnJoin"
                        >
                          {{ $t("joinUs") }}
                        </b-button>
                      </div>

                      <b-modal
                        id="modal-1"
                        title="انضم إلينا كمدرب"
                        hide-footer
                      >
                        <form @submit.prevent="sendJoin" class="my-4 gg">
                          <div>
                            <label for="file">{{ $t("Upload_File") }}:</label>
                            <b-form-file
                              style="direction: ltr; text-align: left"
                              placeholder="اخنر ملف"
                              drop-placeholder="اخنر ملف"
                              type="file"
                              id="file"
                              @change="handleFileChange"
                              ref="fileInput"
                            />
                          </div>
                          <div>
                            <label for="image">{{ $t("Upload_Image") }}</label>
                            <b-form-file
                              style="direction: ltr; text-align: left"
                              type="file"
                              placeholder="اخنر صورة"
                              drop-placeholder="اخنر صورة"
                              id="image"
                              @change="handleImageChange"
                              accept="image/*"
                              ref="imageInput"
                            />
                          </div>
                          <div>
                            <label for="gender">{{ $t("gender") }}</label>
                            <b-form-select
                              v-model="gender"
                              :options="genders"
                            ></b-form-select>
                          </div>
                          <div>
                            <label for="detail">{{ $t("detail") }}</label>
                            <b-form-textarea
                              id="textarea"
                              v-model="detail"
                              placeholder="ادخل التفاصيل"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>
                          </div>
                          <div
                            style="
                              display: flex;
                              width: 100%;
                              justify-content: center;
                            "
                          >
                            <button
                              style="
                                position: relative;
                                z-index: 100;
                                display: flex;
                              "
                              class="button -sm -dark-1 text-white mt-2"
                            >
                              {{ $t("joinUs") }}
                            </button>
                          </div>
                        </form>
                      </b-modal>
                    </div>
                  </div>

                  <!--  -->
                </div>
              </div>

              <div class="col-lg-6 sm:d-none md:d-none lg:d-none">
                <div
                  class="masthead__image relative tf__video_text ml-20 mr-20"
                >
                  <img src="../assets/imgs/hero-main.png" alt="image" />
                  <div
                    class="absolute-full-center d-flex justify-center items-center"
                  >
                    <a
                      v-b-modal="`intro-video`"
                      class="d-flex justify-center items-center play_btn rounded-full bg-white js-gallery"
                    >
                      <div class="icon-play text-18"></div>
                      <IntroVideoModal />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CategoriesComponent />

        <section class="layout-pt-lg layout-pb-lg">
          <div class="container">
            <div class="row justify-center text-center">
              <div class="col-auto">
                <div class="sectionTitle">
                  <h2 class="sectionTitle__title">
                    {{ $t("mostPopularCourses") }}
                  </h2>

                  <p
                    class="sectionTitle__text"
                    v-html="$t('mostPopularCoursesDescription')"
                  ></p>
                </div>
              </div>
            </div>
            <b-tabs align="center" pills card class="my-4">
              <!-- <b-tab class="my-3" no-body title="Top Courses"> </b-tab> -->
              <b-tab class="my-3" :title="$t('recentCourses')">
                <RecentCourses />
              </b-tab>
              <b-tab class="my-3" no-body :title="$t('featuredCourses')">
                <FeaturedCourses />
              </b-tab>
              <b-tab class="my-3" :title="$t('freeCourses')">
                <FreeCourses />
              </b-tab>

              <!-- <b-tab class="my-3" no-body :title="$t('topDiscounted')">
                <TopDiscounted />
              </b-tab> -->

              <b-tab class="my-3" no-body :title="$t('bestSelling')">
                <BestSelling />
              </b-tab>

              <b-tab class="my-3" no-body :title="$t('كورسات مسجلة')">
                <RecordedCourses />
              </b-tab>
              <b-tab class="my-3" no-body :title="$t('كورسات اونلاين')">
                <!-- <BestSelling /> -->
                <OnlineCourses />
              </b-tab>

              <b-tab class="my-3" :title="$t('myCourses')" v-if="isLoggedIn">
                <MyCourses />
              </b-tab>

              <!-- <b-tab class="my-3" :title="$t('bundleCourses')">
                <BundleCourses />
              </b-tab> -->
            </b-tabs>
          </div>
        </section>

        <!--hide from tasneem -->
        <!-- <section class="layout-pt-lg layout-pb-md bg-light-4">
          <div class="container">
            <div class="row y-gap-30 justify-between items-center">
              <div class="col-lg-6 sm:d-none md:d-none lg:d-none">
                <div class="composition -type-4">
                  <div class="-el-1">
                    <img
                      class="w-1/1 img-rounded"
                      src="../assets/imgs/special-instructors.jpg"
                      alt="image"
                    />
                  </div>
                </div>
              </div>

              <div class="col-xl-5 col-lg-6 col-md-9">
                <h3 class="text-45 md:text-30 lh-11">
                  <span class="text-purple-1">{{ $t("courses") }}</span>
                  {{ $t("taughtByIndustryLeaders") }}
                </h3>
                <p
                  class="mt-25"
                  v-html="$t('coursesTaughtByIndustryLeadersDescription')"
                ></p>
                <div class="d-flex x-gap-15 y-gap-15 flex-wrap pt-30">
                  <div>
                    <RouterLink
                      :to="{ name: 'InstructorsView' }"
                      class="button -md -dark-1 text-white"
                      >{{ $t("browseTeachers") }}</RouterLink
                    >
                  </div>
                  <div>
                    <RouterLink
                      :to="{ name: 'JoinAsInstructor' }"
                      class="button -md -outline-dark-1 text-dark-1"
                      >{{ $t("becomeTeacher") }}
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <!--hide from tasneem -->
        <!-- <section
          class="pt-80 pb-80 md:pt-60 md:pb-60 bg-purple-11 background-bg"
        >
          <div class="container">
            <div class="row y-gap-20 justify-between items-center">
              <div class="col-xl-4 col-lg-5">
                <h2 class="text-30 lh-15 text-white">
                  {{ $t("findYourRightCourses") }}
                </h2>
                <p class="text-white mt-10">
                  {{ $t("findingYourRightCourseDescription") }}
                </p>
              </div>

              <div class="col-auto">
                <RouterLink
                  :to="{ name: 'all_categories' }"
                  class="button -md -white text-dark-1"
                  >{{ $t("getStarted") }}
                </RouterLink>
              </div>
            </div>
          </div>
        </section> -->
        <!--start of why us section-->

        <!--hide from tasneem -->
        <!-- <section class="layout-pt-lg layout-pb-lg">
          <div class="container">
            <div class="row y-gap-30 justify-between items-center">
              <div class="col-xl-5 col-lg-6 order-2 order-lg-1">
                <h2 class="text-45 lg:text-40 md:text-30 text-dark-1">
                  {{ $t("why") }} <span class="text-purple-1">WLCD</span>
                </h2>
                <p class="text-dark-1 mt-20 mb-20">
                  {{ FactSlider.sub_heading }}
                </p>

                <div
                  class="row align-items-center mt-20"
                  v-for="fact in FactsSlide"
                  :key="FactsSlide.id"
                >
                  <div class="col-2">
                    <div class="featureIcon -type-1">
                      <div class="featureIcon__icon bg-light-7">
                        <div
                          v-if="fact.icon != 'Choose icon'"
                          :class="`fa ${fact.icon}`"
                          style="color: #6440fb"
                        ></div>
                        <div
                          v-else
                          class="fa fa-check"
                          style="color: #6440fb"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-8 featureIcon__content ml-10 mr-10"
                  >
                    <p class="mt-5">
                      {{ fact.heading }}<br class="lg:d-none" />
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-6 order-1 order-lg-2 sm:d-none md:d-none lg:d-none"
              >
                <div class="composition -type-5">
                  <div class="-el-1">
                    <img
                      src="../assets/imgs/why-us1.jpg"
                      class="img-rounded"
                      alt="image"
                    />
                  </div>

                  <div class="-el-2">
                    <img
                      src="../assets/imgs/why-us2.jpg"
                      class="img-rounded"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <!--end of why us section-->

        <!-- <section class="section-bg layout-pt-lg layout-pb-md">
          <div class="section-bg__item -full -height-half bg-dark-5"></div>

          <div class="container">
            <div class="row justify-center text-center">
              <div class="col-auto">
                <div class="sectionTitle">
                  <h2 class="sectionTitle__title text-white">
                    Start your Learning Journey Today!
                  </h2>

                  <p class="sectionTitle__text text-white">
                    Lorem ipsum dolor sit amet, consectetur.
                  </p>
                </div>
              </div>
            </div>

            <div class="row y-gap-30 justify-between pt-60 lg:pt-50">
              <div class="col-lg-3 col-md-6">
                <div
                  class="coursesCard -type-2 text-center pt-50 pb-40 px-30 rounded-8 bg-white shadow-2"
                >
                  <div class="coursesCard__image">
                    <img
                      src="../assets/img/home-5/learning/1.svg"
                      alt="image"
                    />
                  </div>
                  <div class="coursesCard__content mt-30">
                    <h5 class="coursesCard__title text-18 lh-1 fw-500">
                      Learn with Experts
                    </h5>
                    <p class="coursesCard__text text-14 mt-10">
                      Grursus mal suada faci lisis that ipsum ameti consecte.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div
                  class="coursesCard -type-2 text-center pt-50 pb-40 px-30 rounded-8 bg-white shadow-2"
                >
                  <div class="coursesCard__image">
                    <img
                      src="../assets/img/home-5/learning/2.svg"
                      alt="image"
                    />
                  </div>
                  <div class="coursesCard__content mt-30">
                    <h5 class="coursesCard__title text-18 lh-1 fw-500">
                      Learn Anything
                    </h5>
                    <p class="coursesCard__text text-14 mt-10">
                      Grursus mal suada faci lisis that ipsum ameti consecte.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div
                  class="coursesCard -type-2 text-center pt-50 pb-40 px-30 rounded-8 bg-white shadow-2"
                >
                  <div class="coursesCard__image">
                    <img
                      src="../assets/img/home-5/learning/3.svg"
                      alt="image"
                    />
                  </div>
                  <div class="coursesCard__content mt-30">
                    <h5 class="coursesCard__title text-18 lh-1 fw-500">
                      Flexible Learning
                    </h5>
                    <p class="coursesCard__text text-14 mt-10">
                      Grursus mal suada faci lisis that ipsum ameti consecte.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div
                  class="coursesCard -type-2 text-center pt-50 pb-40 px-30 rounded-8 bg-white shadow-2"
                >
                  <div class="coursesCard__image">
                    <img
                      src="../assets/img/home-5/learning/4.svg"
                      alt="image"
                    />
                  </div>
                  <div class="coursesCard__content mt-30">
                    <h5 class="coursesCard__title text-18 lh-1 fw-500">
                      Industrial Standart
                    </h5>
                    <p class="coursesCard__text text-14 mt-10">
                      Grursus mal suada faci lisis that ipsum ameti consecte.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <RecentBlog />

        <BestInstructor />
        <!-- edit by tasneem -->
        <!-- <section class="layout-pt-lg layout-pb-lg bg-light-3">
          <div class="container">
            <div class="row y-gap-20 items-center">
              <div class="col-xl-7 col-lg-7">
                <div
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-delay="60"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  class="app-image"
                >
                  <img src="../assets/imgs/multi-platforms.png" alt="image" />
                </div>
              </div>

              <div class="col-lg-5">
                <div class="app-content">
                  <h2
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    class="app-content__title"
                  >
                    تطبيق WLCD
                  </h2>
                  <p
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    class="app-content__text"
                  >
                    {{ $t("installAppsDescription") }}
                  </p>
                  <div
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    class="app-content__buttons"
                  >
                    <a href="#"
                      ><img src="@/assets/img/app/buttons/1.webp" alt="button"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/app/buttons/2.webp" alt="button"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <!-- <TestmonialComponent /> -->

        <!-- <section class="layout-pt-lg layout-pb-lg bg-light-7">
          <div class="container">
            <div class="row justify-center text-center">
              <div class="col-xl-6 col-lg-8 col-md-10">
                <div class="sectionTitle">
                  <h2 class="sectionTitle__title">We are Proud</h2>

                  <p class="sectionTitle__text">
                    Certificate courses are instructed by highly educated and
                    qualified instructors who hold doctoral and master’s level
                    degrees.
                  </p>
                </div>
              </div>
            </div>

            <div class="row y-gap-30 pt-60 lg:pt-40">
              <div class="col-lg-3 col-sm-6">
                <div class="counter -type-1">
                  <div class="counter__number text-purple-1">350,000+</div>
                  <div class="counter__title text-light-1">
                    Students worldwide
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                <div class="counter -type-1">
                  <div class="counter__number text-purple-1">496,00+</div>
                  <div class="counter__title text-light-1">
                    Total course views
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                <div class="counter -type-1">
                  <div class="counter__number text-purple-1">19,000+</div>
                  <div class="counter__title text-light-1">
                    Five-star course reviews
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                <div class="counter -type-1">
                  <div class="counter__number text-purple-1">987,000+</div>
                  <div class="counter__title text-light-1">
                    Students community
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="layout-pt-md layout-pb-lg">
          <div class="container">
            <div class="row justify-center">
              <div class="col text-center">
                <p class="text-lg text-dark-1">Trusted by the world’s best</p>
              </div>
            </div>

            <div
              class="row y-gap-30 justify-between sm:justify-start items-center pt-60 md:pt-50"
            >
              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/1.svg"
                    alt="clients image"
                  />
                </div>
              </div>

              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/2.svg"
                    alt="clients image"
                  />
                </div>
              </div>

              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/3.svg"
                    alt="clients image"
                  />
                </div>
              </div>

              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/4.svg"
                    alt="clients image"
                  />
                </div>
              </div>

              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/5.svg"
                    alt="clients image"
                  />
                </div>
              </div>

              <div class="col-lg-auto col-md-2 col-sm-3 col-4">
                <div class="d-flex justify-center items-center px-4">
                  <img
                    class="w-1/1"
                    src="../assets/img/clients/6.svg"
                    alt="clients image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> -->
      </div>
    </div>
    <HomeFooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import CategoriesComponent from "@/components/HomeComponent/CategoriesComponent.vue";
import TestmonialComponent from "@/components/HomeComponent/TestmonialComponent.vue";
import BestInstructor from "@/components/HomeComponent/BestInstructorComponent.vue";
import VueCarousel from "@/components/shared-component/vue-carousel.vue";
import HomeFooterComponent from "@/components/FooterComponent.vue";
import HomeNavBarComponent from "@/components/NavBarComponent.vue";
import BestSelling from "@/components/CoursesCategories/BestSelling.vue";
import OnlineCourses from "@/components/CoursesCategories/OnlineCourses.vue";
import RecordedCourses from "@/components/CoursesCategories/RecordedCourses.vue";
import BundleCourses from "@/components/CoursesCategories/BundleCourses.vue";
import FeaturedCourses from "@/components/CoursesCategories/FeaturedCourses.vue";
import FreeCourses from "@/components/CoursesCategories/FreeCourses.vue";
import MyCourses from "@/components/CoursesCategories/MyCourses.vue";
import RecentCourses from "@/components/CoursesCategories/RecentCourses.vue";
import TopDiscounted from "@/components/CoursesCategories/TopDiscounted.vue";
import RecentBlog from "@/components/HomeComponent/RecentBlogComponent.vue";

import "particles.js";
import { mapState, mapActions } from "vuex";
import { RouterLink } from "vue-router";
import IntroVideoModal from "@/components/HomeComponent/IntroVideoModal.vue";
export default {
  name: "HomeView",

  components: {
    HomeFooterComponent,
    HomeNavBarComponent,
    CategoriesComponent,
    TestmonialComponent,
    BestInstructor,
    TopDiscounted,
    RecentCourses,
    MyCourses,
    FreeCourses,
    FeaturedCourses,
    BundleCourses,
    BestSelling,
    VueCarousel,
    RecentBlog,
    RouterLink,
    IntroVideoModal,
    OnlineCourses,
    RecordedCourses,
  },
  data() {
    return {
      image: null,
      file: null,
      detail: null,
      gender: null,
      showModal: false,

      genders: [{ text: "اختر الجنس", value: null }, "male", "female"],
    };
  },
  mounted() {
    this.initParticles();
  },
  computed: {
    ...mapState(["categories", "isLoggedIn", "FactsSlide", "FactSlider"]), // map the 'products' state to a local computed property
  },
  methods: {
    ...mapActions([
      "fetchCategories",
      "SET_LOGGED_IN",
      "fetchFacts",
      "sendJoinUs",
    ]),
    initParticles() {
      window.particlesJS("js-masthead-1-particles", {
        particles: {
          number: {
            value: 80,
            density: {
              enable: false,
              value_area: 200,
            },
          },
          color: {
            value: ["#00ff96", "#0044EB", "#1A3454"],
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 6,
            random: true,
            anim: {
              enable: false,
              speed: 20,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "bounce",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "repulse",
            },
            onclick: {
              enable: false,
              mode: "push",
            },
            resize: false,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    },

    //
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    handleImageChange(event) {
      this.image = event.target.files[0];
    },
    async sendJoin() {
      const reviewData = {
        file: this.file,
        image: this.image,
        detail: this.detail,
        gender: this.gender,
      };

      try {
        await this.sendJoinUs({ reviewData });
        this.resetForm();
      } catch (error) {
        console.error("Failed to submit form:", error);
      }
    },
    resetForm() {
      this.file = null;
      this.image = null;
      (this.detail = null),
        (this.gender = null),
        (this.$refs.fileInput.value = "");
      this.$refs.imageInput.value = "";
    },

    //
  },
  created() {
    this.fetchCategories(); // automatically fetch products when the component is created
    this.fetchFacts(); // automatically fetch products when the component is created
  },
};
</script>

<style scoped>
#btnJoin {
  width: inherit !important;
}
.gg {
  direction: rtl !important;
}
#js-masthead-1-particles {
  /* background: #007b4e; */
  height: auto;
  /* width: 100%; */
  top: 100px;
}
.owl-carousel .owl-item img {
  display: inline !important;
  width: auto !important;
}
.testimonial-pic {
  box-shadow: 0 0 0 2px #e6e6e6;
  width: 70px;
  height: 70px;
}
@media only screen and (max-width: 480px) {
  .testimonial-pic {
    height: 80px;
    width: 80px;
  }
}
.testimonial-pic {
  border-color: #efbb20;
  border-width: 3px;
  width: 90px;
  height: 90px;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  display: inline-block;
  border: 5px solid #fff;
}

.quote-left {
  position: relative;
}

.quote-left:before {
  width: 36px;
  height: 36px;
  line-height: 60px;
  font-size: 16px;
  /*background: #EFBB20;*/
  background-color: #e52d27;
  color: #fff;
  text-align: center;
  left: 0;
  top: 85px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.quote-left:before {
  content: open-quote;
  font-size: 60px;
  left: 0;
}
.quote-left:before {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 10;
  top: 65px;
}
.owl-dots-style .owl-dots .owl-dot.active span {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-width: 2px;
}

.owl-dots-style .owl-dots .owl-dot.active span,
.header-nav .nav > li > a.active,
#myNavbar li.active a {
  border-color: #e52d27;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-1 .testimonial-position {
  color: #efbb20;
}
.testimonial-1 {
  text-align: center;
}
.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position,
.testimonial-name,
.testimonial-position {
  display: block;
}
.testimonial-bg {
  color: #fff;
}
.testimonial-text {
  padding: 15px;
  position: relative;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: #000;
}
.owl-prev {
  left: 0;
}
.owl-next {
  right: 0;
}

.img-rounded {
  border-radius: 50%;
}
</style>
