export default {
  ar: {
    instructors: "المدربون",
    Upload_File: "ارفع ملف",
    Upload_Image: "ارفع صورة",
    gender: "الجنس",
    detail: "التفاصيل",
    joinUs: "انضم الينا",
    instructorsIntro: `نحن فخورون بأن نقدم لكم أفضل المدربين والمحترفين في مختلف المجالات لتحقيق تجربة تعليمية مميزة.
        <br>
        ستتعرف على المدربين الرائعين الذين يقدمون لك الدورات التعليمية في منصة WLCD. كما ستتعرف على خبراتهم ومؤهلاتهم وشهاداتهم وإنجازاتهم في مجالاتهم. 
        `,
    whyChoosingBestInstructors: `لماذا نختار أفضل المدرسين؟`,
    whyChoosingBestInstructorsDescription: ` نؤمن في منصة WLCD بأن جودة التعليم تعتمد بشكل كبير على جودة
        المدربين. لذلك، نحرص على اختيار أفضل المدربين في مختلف المجالات
        والتخصصات. نختار مدربينا بعناية فائقة وبناءً على معايير عالية
        تضمن تقديم دورات تعليمية متطورة ومحدثة وفعّالة.`,
    whatAreChoosingStandards: `ما هي معايير اختيار المدربين؟`,
    whatAreChoosingStandardsDescription: `نختار مدربينا بناءً على معايير تشمل`,
    choosingStandards: [
      {
        title: `الخبرة العملية`,
        description: `نختار مدربين لديهم خبرة عملية طويلة وواسعة في مجالاتهم، ولديهم نظرة عملية وواقعية للمشكلات والحلول.`,
      },
      {
        title: `المعرفة النظرية`,
        description: `نختار مدربين لديهم معرفة نظرية عميقة وشاملة في مجالاتهم، ولديهم قدرة على شرح المفاهيم والأسس بطريقة سهلة وواضحة.`,
      },
      {
        title: `الشهادات والاعتمادات`,
        description: `نختار مدربين لديهم شهادات واعتمادات رسمية تثبت مستواهم وكفاءتهم في مجالاتهم، وتزيد من ثقة المستخدمين بهم.`,
      },
      {
        title: `الإنجازات والجوائز`,
        description: `نختار مدربين لديهم إنجازات وجوائز تبرز تفوقهم وابداعهم في مجالاتهم، وتشجع المستخدمين على التعلم منهم.`,
      },
      {
        title: `الشغف والحب`,
        description: `نختار مدربين يحبون مجالاتهم ويرغبون في نقل شغفهم إلى المستخدمين، ويلتزمون بأخلاقيات التعليم والاحترام.`,
      },
    ],
  },
  en: {
    instructors: "Instructors",
    joinUs: "join Us",
    Upload_File: "Upload File",
    Upload_Image: "Upload Image",
    gender: "gender",
    detail: "details",
    instructorsIntro:
      "We are proud to offer you the best trainers and professionals in various fields to achieve a unique learning experience.\n<br>\nYou will get to know the wonderful trainers who provide you with educational courses on the WLCD platform. You will also learn about their experiences, qualifications, certifications, and achievements in their fields.",
    whyChoosingBestInstructors: "Why choose the best instructors?",
    whyChoosingBestInstructorsDescription:
      "At WLCD platform, we believe that the quality of education relies heavily on the quality of instructors. Therefore, we are committed to selecting the best instructors in various fields and specialties. We carefully choose our trainers based on high standards to ensure the delivery of advanced, up-to-date, and effective educational courses.",
    whatAreChoosingStandards:
      "What are the criteria for selecting instructors?",
    whatAreChoosingStandardsDescription:
      "We select our trainers based on criteria that include:",
    choosingStandards: [
      {
        title: "Practical Experience",
        description:
          "We choose trainers who have long and extensive practical experience in their fields and possess a practical and realistic approach to problems and solutions.",
      },
      {
        title: "Theoretical Knowledge",
        description:
          "We choose trainers who have deep and comprehensive theoretical knowledge in their fields and have the ability to explain concepts and foundations in an easy and clear manner.",
      },
      {
        title: "Certifications and Accreditations",
        description:
          "We choose trainers who have official certifications and accreditations that demonstrate their level of competence and expertise in their fields, increasing users' confidence in them.",
      },
      {
        title: "Achievements and Awards",
        description:
          "We choose trainers who have achievements and awards that highlight their excellence and creativity in their fields, encouraging users to learn from them.",
      },
      {
        title: "Passion and Dedication",
        description:
          "We choose trainers who love their fields, aspire to transfer their passion to users, and are committed to the ethics of education and respect.",
      },
    ],
  },
};
