<template>
  <div class="freecourses">
    <div class="relative my-4">
      <div class="col-lg-auto my-2 justify-view">
        <RouterLink
          :to="{ name: 'AllFreeCourses' }"
          class="button -icon -purple-3 text-purple-1"
        >
        {{ $t('browseAll') }}
          <i class="icon-arrow-top-right text-13 mr-10 ml-10"></i>
        </RouterLink>
      </div>
      <CourseListCarousel
        :responsive="responsive"
        v-if="freecourses.length > 0"
        :courses="freecourses"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CourseListCarousel from "@/components/shared-component/CourseListCarousel.vue";

export default {
  name: "FreeCourses",
  components: {
    CourseListCarousel,
  },
  computed: {
    ...mapState(["freecourses"]),
    responsive() {
      return [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 998,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchFreeCourses"]),
  },
  created() {
    this.fetchFreeCourses(); // automatically fetch products when the component is created
  },
};
</script>
