<template>
  <div id="app" :class="{ rtl: isRTL }">
    <!--overlay of loader-->
    <b-overlay
      v-if="loading"
      no-wrap
      fixed
      show
      spinner-type="grow"
      spinner-small
      rounded="sm"
      z-index="50"
    >
    </b-overlay>
    <!--router view-->
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    isRTL() {
      const rtlLocales = ["ar"]; // RTL locale
      return rtlLocales.includes(this.$i18n.locale);
    },
    ...mapState(["loading"]),
  },
  data() {
    return {
      isNavigationFixed: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.toggleNavigation);
  },
  methods: {
    toggleNavigation() {
      const offset = 300;
      if (window.scrollY >= offset) {
        this.isNavigationFixed = true;
      } else {
        this.isNavigationFixed = false;
      }
    },
    openNav() {
      document.getElementById("mySidenav").style.width = "250px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleNavigation);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
#app {
  font-family: "Tajawal", serif !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.rtl {
  direction: rtl;
  text-align: right;
}

/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}*/

.image-width {
  width: 250px !important;
}
</style>
