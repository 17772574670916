import AboutUsPageTranslations from "./translations/AboutUsPageTranslations";
import AllCategoriesPageTranslations from "./translations/AllCategoriesPageTranslations";
import BlogsPageTranslations from "./translations/BlogsPageTranslations";
import CartTranslations from "./translations/CartTranslations";
import ContactUsPageTranslations from "./translations/ContactUsPageTranslations";
import CourseContentTranslations from "./translations/CourseContentTranslations";
import CourseDetailsTranslations from "./translations/CourseDetailsTranslations";
import FooterTranslations from "./translations/FooterTranslations";
import HomeTranslations from "./translations/HomePageTranslations";
import InstructorProfileTranslations from "./translations/InstructorProfileTranslations";
import InstructorsPageTranslations from "./translations/InstructorsPageTranslations";
import LoginPageTranslations from "./translations/LoginPageTranslations";
import MessagesTranslations from "./translations/MessagesTranslations";
import NavigationTranslations from "./translations/NavigationTranslations";
import ProfileTranslations from "./translations/ProfileTranslations";
import SignUpPageTranslations from "./translations/SignUpPageTranslations";
import ValidationErrorMessages from "./translations/ValidationErrorMessages";

export default {
  //NAVIGATION TRANSLATIONS
  ...NavigationTranslations["ar"],

  //FOOTER TRANSLATIONS
  ...FooterTranslations["ar"],

  //HOME PAGE TRANSLATIONS
  ...HomeTranslations["ar"],

  //INSTRUCTORS PAGE TRANSLATIONS
  ...InstructorsPageTranslations["ar"],
   //INSTRUCTOR PROFILE PAGE TRANSLATIONS
  ...InstructorProfileTranslations["ar"],

  //ABOUT US PAGE TRANSLATIONS
  ...ContactUsPageTranslations["ar"],

  //All CATEGORIES PAGE TRANSLATIONS
  ...AllCategoriesPageTranslations["ar"],

  //BLOGS PAGE TRANSLATIONS
  ...BlogsPageTranslations["ar"],

  //ABOUT US PAGE TRANSLATIONS
  ...AboutUsPageTranslations["ar"],

  //LOGIN PAGE TRANSLATIONS
  ...LoginPageTranslations["ar"],

  //SIGN UP PAGE TRANSLATIONS
  ...SignUpPageTranslations["ar"],

  //COURSE DETAILS TRANSLATIONS
  ...CourseDetailsTranslations["ar"],

  //CART TRANSLATIONS
  ...CartTranslations["ar"],

  //COURSE CONTENT TRANSLATIONS
  ...CourseContentTranslations["ar"],

  //PROFILE TRANSLATIONS
  ...ProfileTranslations["ar"],
  
  //VALIDATION MESSAGES TRANSLATIONS
  ...ValidationErrorMessages["ar"],

  //MESSAGES
  ...MessagesTranslations["ar"],
};
