export default {
  ar: {
    mostPopularCourses:"ابدأ تدريبك الآن",
    courses:"دورات",
    taughtByIndustryLeaders:"مقدمة من الخبراء",
    browseTeachers:"استعراض المدربين",
    becomeTeacher:"انضم لتكون مدرباً",
    findYourRightCourses:"ابحث عن الدورات المناسبة لك",
    getStarted:"ابدأ الآن",
    why:"لماذا",
    learnFrom:"تعلم من",
    anywhere:"أي مكان",
    learnFromBestInstructors:"تعلم مع أفضل المدربين",
    topCategories:"المجالات الأكثر طلبًا لعام 2024",
    recentBlogs:"المدونات الحديثة",
    testimonials:"الشهادات",
    topDiscounted:"الدورات ذات الخصوم",
    bestSelling:"الدورات الأكثر مبيعاً",
    featuredCourses:"الدورات المميزة",
    myCourses:"دوراتي",
    freeCourses:"الدورات المجانية",
    recentCourses:"أحدث التدريبات",
    bundleCourses:"حزم الدورات",
    browseAll:"استعراض الجميع",
    heroSectionTitle: `ابتكر … تفاعل … وتعلّم <br />  <span class="text-purple-1">بدون حدود</span>`,
    heroSection: `تخطى حدود المعرفة التقليدية واستعد للانطلاق نحو مستقبلٍ مشرق. استمتع بمحتوى تعليمي متنوع وشيق يغطي مجموعة واسعة من المواضع.`,
    joinUs: "انضم إلينا",
    educationalCourses: "الدورات التعليمية",
    categoriesDescription: `نقدم في WLCD مجموعة متنوعة من الدورات المصممة خصيصًا لتلبية احتياجاتك الشخصية والمهنية. سجّل الآن وسنكون معك خطوة بخطوة لدعمك في تحقيق أهدافك وطموحاتك.`,
    mostPopularCoursesDescription: `نؤمن في WLCD بأن التعلم هو مفتاح النمو والتطور، لذلك نقدم لك تجربة تعليمية مميزة ومثمرة تمكنك من الارتقاء بذاتك ومهاراتك. سجّل الآن وابدأ رحلتك نحو مستقبلٍ مُشرق!`,
    coursesTaughtByIndustryLeadersDescription: `نضمن لك في منصة WLCD، أن تتعلم من أفضل الخبراء والمختصين
        المرموقين حول العالم. مدرسينا هم من النخبة في مجالاتهم ولديهم
        خبرات واسعة ومعرفة عميقة. ستستفيد من دوراتنا التعليمية التي
        تقدم لك مهارات جديدة أو تطوير مهاراتك الحالية بطريقة عملية
        وفعالة. ستتلقى من مدرسينا الملاحظات والإرشادات التي تساعدك على
        تحسين أدائك وزيادة ثقتك في مجالك.
        <br />
        تتميز منصة WLCD بأنها تجمع بين مدرّسين من ذوي الخبرة الواسعة
        في مختلف المجالات والصناعات. يتميز أساتذتنا بالتفاني والشغف في
        تقديم المعرفة وتبسيط المفاهيم لجعل عملية التعلم ممتعة وفعالة.
        ولا يقتصر دور المدرسين في تقديم المعلومات فقط، بل يسعون أيضًا
        لتبادل الخبرات والمعرفة القيمة المستمدة من تجاربهم الشخصية
        كممارسين ناجحين في مجالاتهم.
        <br />
        نحن في انتظارك لتنضم إلى منصتنا اليوم وتستفيد من فرصة تعلم
        استثنائية على يد مدرّسين مميزين يشاركونك شغفهم بنقل المعرفة
        والمساهمة في نجاحك التعليمي والمهني.`,
    findingYourRightCourseDescription: `استعد لاكتساب المعرفة وتحقيق أهدافك التعليمية والمهنية مع منصة WLCD! ندرك أهمية اختيار الدورة التدريبية التي تناسب احتياجاتك وتطلعاتك، ولهذا السبب نقدم لك مجموعة كبيرة من الدورات المميزة للاختيار من بينها.
      
        سواء كنت تهدف إلى تحسين مهاراتك الشخصية أو الوصول إلى طموحاتك التعليمية أو المهنية، نؤمن بأن لدينا الدورة التدريبية المثالية لك. اختر الدورة التي تناسبك وتمتع بمحتوى عالي الجودة تم إعداده وتقديمه من قبل خبراء متميزين في مختلف المجالات.
        `,
    recentBlogsDescription: `نهدف من خلال مدونتنا إلى توفير أفضل مصدر موثوق ومفيد للمتعلمين الطموحين الذين يسعون لتطوير مهاراتهم ومعارفهم في مختلف المجالات `,
    bestInstructorsDescription: `
        استعد لتجربة تعليمية لا مثيل لها، حينما تتعلم من أفضل المدربين في منصة
              WLCD. نحن فخورون بتقديم مجموعة من أشهر المدربين في الصناعة، الذين
              يتمتعون بخبرات هائلة ومعرفة عميقة في مجالاتهم.
              
        `,
    learnFromAnyWhereDescription: `تعلم من أي مكان وفي أي وقت.`,
    installAppsDescription: `حمّل تطبيق WLCD الآن واستمتع بمحتوى تعليمي وتفاعلي عالي الجودة يقدمه أفضل الخبراء في مختلف المجالات.    `,
    faqs: "الأسئلة الشائعة",
    faqsIntro: "نستعرض أبرز الأسئلة الشائعة",
  },
  en: {
    mostPopularCourses:"Most Popular Courses",
    courses:"Courses",
    taughtByIndustryLeaders:"Taught by Industry Leaders",
    browseTeachers:"Browse Teachers",
    becomeTeacher:"Become a Teacher",
    findYourRightCourses:"Find your Courses",
    getStarted:"Get Started",
    why:"Why",
    learnFrom:"Learn From ",
    anywhere:"Anywhere",
    learnFromBestInstructors:"Learn from Best Instructors",
    topCategories:"Top Categories",
    recentBlogs:"Recent Blogs",
    testimonials:"Testimonials",
    topDiscounted:"Top Discounted",
    bestSelling:"Best Selling",
    featuredCourses:"Featured",
    myCourses:"My courses",
    freeCourses:"Free",
    recentCourses:"Recent",
    bundleCourses:"Bundles",
    browseAll:"Browse All",
    heroSectionTitle: `Innovate, Interact, and Learn Without Boundaries`,
    heroSection: `Surpass the limits of traditional knowledge and get ready to take off towards a bright future. Enjoy diverse and engaging educational content covering a wide range of topics, whether you are a student seeking academic excellence or a professional looking to develop your skills. You will find here an inspiring and supportive environment that helps you grow personally and professionally.`,
    joinUs: `Join Us`,
    educationalCourses: `Educational Courses`,
    categoriesDescription: `If you want to acquire new skills or improve your level in any field, you are in the right place. WLCD platform is a pioneering educational platform that provides you with the latest and best courses in various fields and specializations. You will find on the WLCD platform courses that match your interests and ambitions, whether you want to design a website or develop an innovative application or manage a successful project or market a product or service or learn a new language or solve mathematical problems or discover and develop your artistic talents, you will find the right course for you. Join our platform today and benefit from a variety of courses specially designed to meet your personal and professional needs. Start your journey towards learning and self-development with us, and we will be waiting for you to support you in achieving your goals and realizing your ambitions. All our educational courses are high quality and easy to understand, so joining the WLCD platform will be a good investment of your time and effort. We are proud to provide a distinctive virtual educational experience, where you can access our courses at any time and from anywhere, giving you flexibility in your schedule and allowing you to learn the skills you need without conflicting with your daily needs.`,
    mostPopularCoursesDescription: `At the WLCD platform, we have a wide range of training courses that meet your needs and aspirations in various fields and specializations. These courses have been carefully selected to enable you to acquire knowledge and develop essential skills in an enjoyable and easy way.<br /><br />We at the WLCD platform believe that learning is the key to growth and development, and we strive diligently to provide a distinctive and fruitful educational experience that enables you to rise yourself and your skills. Therefore, we offer you diverse, up-to-date, and advanced courses that suit your level, time, and capabilities. You will find courses on the WLCD platform that encourage you to interact, create, and innovate.<br /><br /><span>Join us now and start your journey in the world of knowledge and development!</span>`,
    coursesTaughtByIndustryLeadersDescription: `At the WLCD platform, we ensure that you learn from the best experts and renowned professionals around the world. Our teachers are elites in their fields and have extensive experience and in-depth knowledge. You will benefit from our educational courses that offer you new skills or develop your current skills in a practical and effective way. You will receive from our teachers the notes and guidance that help you improve your performance and increase your confidence in your field.<br /><br />The WLCD platform is distinguished by bringing together teachers with extensive experience in various fields and industries. Our teachers excel in passionately conveying knowledge and simplifying concepts to make the learning process enjoyable and effective. And the role of teachers is not limited to providing information only, but they also seek to exchange valuable knowledge and experiences derived from their personal practical experiences as successful practitioners in their fields.<br /><br />We await you to join our platform today and benefit from an exceptional learning opportunity under the guidance of outstanding teachers who share their passion for transferring knowledge and contributing to your educational and professional success.`,
    findingYourRightCourseDescription: `Get ready to acquire knowledge and achieve your educational and professional goals with the WLCD platform! We understand the importance of choosing the training course that meets your needs and aspirations, which is why we provide you with a wide range of outstanding courses to choose from.<br /><br />Whether you aim to improve your personal skills or achieve your educational or career ambitions, we believe we have the ideal training course for you. Choose the course that suits you and enjoy high-quality content prepared and delivered by distinguished experts in various fields.`,
    recentBlogsDescription: `The WLCD blog contains a variety of topics covering a wide range of different fields. This blog aims to provide a trusted and useful source for learners seeking information, tips, and guidance in various fields.<br /><br />You can read detailed educational articles in our blog on various topics, such as business, marketing, technology, social sciences, education and training, etc. The blog is regularly updated to include the latest news and trends in different fields to ensure providing the most up-to-date and important content to learners.`,
    bestInstructorsDescription: `Get ready for an unparalleled learning experience when you learn from the best instructors on the WLCD platform. We are proud to provide a collection of the most famous instructors in the industry, who have immense experience and in-depth knowledge in their fields.`,
    learnFromAnyWhereDescription: `The WLCD platform provides learners the opportunity to learn from anywhere and at any time, as it provides an advanced electronic application that can be accessed from smartphones, tablets, and desktop computers. This application allows learners to access high-quality educational content and improve their skills and increase their knowledge in their various fields.`,
    installAppsDescription: `Download the WLCD app now and enjoy educational and interactive content anytime, anywhere.`,
    faqs: `Frequently Asked Questions`,
    faqsIntro: `We discuss the most common questions`,
  },
};
