import httpClient from "../client/httpClient";
import Vue from "vue";
import { EventBus } from "@/main";

const state = {};

const mutations = {};

const actions = {
  addToWishlist({ commit }, courseID) {
    commit("setLoading", true);
    httpClient
      .post(
        "/addtowishlist",
        { course_id: courseID },
        {
          withCredentials: false,
        }
      )
      .then((response) => {
        commit("setLoading", false);
        if (response.data.status == "fail") throw Error(response.data.message);
        Vue.$toast.open({
          message: "Course added to wishlist!",
          type: "success",
        });
        EventBus.$emit("courseAddedToWishlist", courseID);
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "something wrong happened",
          type: "error",
        });
        console.error(error);
      });
  },
  removeFromWishlist({ commit }, courseID) {
    commit("setLoading", true);
    httpClient
      .post(
        "/remove/wishlist",
        { course_id: courseID },
        {
          withCredentials: false,
        }
      )
      .then((response) => {
        commit("setLoading", false);
        if (response.data.status == "fail") throw Error(response.data.message);
        Vue.$toast.open({
          message: "Course removed from wishlist!",
          type: "success",
        });
        EventBus.$emit("courseRemovedFromWishlist", courseID);
      })
      .catch((error) => {
        commit("setLoading", false);
        Vue.$toast.open({
          message: error?.response?.data ?? "something wrong happened",
          type: "error",
        });
        console.error(error);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
