<template>
  <div class="bestselling">
    <div class="relative my-4">
      <div class="col-lg-auto my-2 justify-view">
        <RouterLink
          :to="{ name: 'AllRecord' }"
          class="button -icon -purple-3 text-purple-1"
        >
          {{ $t("browseAll") }}
          <!-- sdsdsd -->
          <i class="icon-arrow-top-right text-13 mr-10 ml-10"></i>
        </RouterLink>
      </div>
      <CourseListCarousel
        :responsive="responsive"
        v-if="recordCourses.length > 0"
        :courses="recordCourses"
      />
    </div>
  </div>
</template>

<script>
import CourseListCarousel from "@/components/shared-component/CourseListCarousel.vue";
import { RouterLink } from "vue-router";
import { mapState, mapActions } from "vuex";

export default {
  name: "recordCourses",
  components: {
    CourseListCarousel,
    RouterLink,
  },
  computed: {
    ...mapState(["recordCourses"]),
    responsive() {
      return [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 998,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchRecordCourses"]),
    getCourseImageUrl(preview_image) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/course/";
      return baseUrl + preview_image;
    },

    getUserImageUrl(user_img) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/user_img/";
      return baseUrl + user_img;
    },
  },
  created() {
    this.fetchRecordCourses(); // automatically fetch products when the component is created
  },
};
</script>
