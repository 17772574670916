import httpClient from "../client/httpClient";
import Vue from "vue";

const CourseContentModule = {
  state: () => ({
    progress: null,
    announcements: [],
    certificateFile: null,
    assignments: [],
    quizzes: [],
    quiz: null,
    quizReport: null,
    liveClasses: [],
    questions: [],
    previousPapers: [],
  }),
  mutations: {
    setProgress(state, progress) {
      state.progress = progress;
    },
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },
    certificateFile(state, certificateFile) {
      state.certificateFile = certificateFile;
    },
    changeCheckedChapters(state, chaptersId) {
      state.progress.mark_chapter_id = chaptersId;
    },
    setAssignments(state, assignments) {
      state.assignments = assignments;
    },
    deleteAssignment(state, deletedAssignmentID) {
      state.assignments.splice(
        state.assignments.findIndex((item) => item.id === deletedAssignmentID),
        1
      );
    },
    setQuizzes(state, quizzes) {
      state.quizzes = quizzes;
    },
    setQuiz(state, quiz) {
      state.quiz = quiz;
    },
    setQuizReport(state, report) {
      state.quizReport = report;
    },
    resetQuiz(state) {
      state.quiz = null;
    },
    resetQuizReport(state) {
      state.quizReport = null;
    },
    setLiveClasses(state, classes) {
      state.liveClasses = classes;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    filterQuestion(state, questionId) {
      state.questions = state.questions.filter(
        (question) => question.id != questionId
      );
    },
    filterAnswer(state, answerId) {
      var questions = [];
      state.questions.map((question) => {
        var answers = question.answers.filter(
          (answer) => answer.id != answerId
        );
        question.answers = answers;
        questions.push(question);
      });
      state.questions = questions;
    },
    setPreviousPapers(state, previousPapers) {
      state.previousPapers = previousPapers;
    },
  },
  actions: {
    async fetchProgress({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/progress", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setProgress", response.data.progress);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async updateProgress({ commit }, progressData) {
      commit("setLoading", true);
      await httpClient
        .post("/course/progress/update", {
          course_id: progressData.courseId,
          checked: progressData.selectedChapters,
        })
        .then((response) => {
          commit("setLoading", false);
          commit("changeCheckedChapters", progressData.selectedChapters);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchCertificate({ commit }, progressId) {
      commit("setLoading", true);
      await httpClient
        .get(`/certificate/${progressId}CR-64edd718c9d88`, {
          responseType: "blob",
        })
        .then((response) => {
          commit("setLoading", false);
          const blob = new Blob([response.data]);
          const objectUrl = URL.createObjectURL(blob);
          commit("certificateFile", objectUrl);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchAnnouncements({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/announcement", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setAnnouncements", response.data.announcements);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchPreviousAssignments({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/myAssignments", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setAssignments", response.data.assignments);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitAssignment({ commit }, assignmentData) {
      commit("setLoading", true);
      await httpClient
        .post(
          "/course/assignment",
          {
            course_id: assignmentData.courseId,
            title: assignmentData.title,
            file: assignmentData.file,
            chapter_id: assignmentData.chapter_id,
          },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async deleteAssignment({ commit }, assignmentID) {
      commit("setLoading", true);
      await httpClient
        .post("/course/assignment/delete", {
          assignment_id: assignmentID,
        })
        .then((response) => {
          commit("setLoading", false);
          commit("deleteAssignment", assignmentID);
          Vue.$toast.open({
            message: "deleted successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchQuizzes({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/quizzes", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setQuizzes", response.data.quiz);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchQuiz({ commit }, quizId) {
      commit("setLoading", true);
      await httpClient
        .get("/quiz/get", { params: { quiz_id: quizId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setQuiz", response.data.quiz[0]);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchQuizReport({ commit }, quizId) {
      commit("setLoading", true);
      await httpClient
        .get(`/quiz/reports/${quizId}`)
        .then((response) => {
          commit("setLoading", false);
          commit("setQuizReport", response.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitQuiz({ commit }, { course_id, topic_id, question_id, answer }) {
      commit("setLoading", true);
      await httpClient
        .post("/quiz/submit", {
          course_id: course_id,
          topic_id: topic_id,
          question_id: question_id,
          answer: answer,
        })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchLiveClasses({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/googleMeetings", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setLiveClasses", response.data.google_meet);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchQuestions({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/questions", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setQuestions", response.data.questions);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitQuestion({ commit }, { courseId, question }) {
      commit("setLoading", true);
      await httpClient
        .post("/question/submit", {
          course_id: courseId,
          question: question,
        })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitAnswer({ commit }, { courseId, questionId, answer }) {
      commit("setLoading", true);
      await httpClient
        .post("/answer/submit", {
          course_id: courseId,
          question_id: questionId,
          answer: answer,
        })
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitReviewReport(
      { commit },
      { courseId, reviewId, title, email, detail }
    ) {
      commit("setLoading", true);
      await httpClient
        .post("/review/report", {
          course_id: courseId,
          id: reviewId,
          title: title,
          email: email,
          detail: detail,
        })
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "report sent successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitQuestionReport(
      { commit },
      { courseId, questionId, title, email, detail }
    ) {
      commit("setLoading", true);
      await httpClient
        .post("/question/report", {
          course_id: courseId,
          id: questionId,
          title: title,
          email: email,
          detail: detail,
        })
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "report sent successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async deleteQuestion({ commit }, questionId) {
      commit("setLoading", true);
      await httpClient
        .delete(`/question/delete/${questionId}`)
        .then((response) => {
          commit("setLoading", false);
          commit("filterQuestion", questionId);
          Vue.$toast.open({
            message: "deleted successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async deleteAnswer({ commit }, answerId) {
      commit("setLoading", true);
      await httpClient
        .delete(`/answer/delete/${answerId}`)
        .then((response) => {
          commit("setLoading", false);
          commit("filterAnswer", answerId);
          Vue.$toast.open({
            message: "deleted successfully",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchPreviousPapers({ commit }, courseId) {
      commit("setLoading", true);
      await httpClient
        .get("/course/previousPapers", { params: { course_id: courseId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setPreviousPapers", response.data.PreviousPapers);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
  },
};
export default CourseContentModule;
