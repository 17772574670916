import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import WishListModule from "./modules/WishListModule";
import CourseModule from "./modules/CourseModule";
import CartModule from "./modules/CartModule";
import CourseContentModule from "./modules/CourseContentModule";
import BundleModule from "./modules/BundleModule";
import ProfileModule from "./modules/ProfileModule";
import InstructorModule from "./modules/InstructorModule";
import GeneralModule from "./modules/GeneralModule";
import CourseFilterModule from "./modules/CourseFilterModule";
import AuthModule from "./modules/AuthModule";
import VueCookie from "vue-cookie";
import axios from "axios";

import router from "../router"; // Adjust the import based on your project structure

const BASE_URL = process.env.VUE_APP_BASE_API_URL;

Vue.use(VueCookie);
//TODO refactor to use axios with interceptors and set loading state in interceptor
//TODO refactor separate elements into modules
Vue.use(Vuex);
setTimeout(() => {
  localStorage.clear();
  location.reload();
}, 7 * 24 * 60 * 60 * 1000); // 24 hours

// const BASE_URL = process.env.VUE_APP_BASE_API_URL;
const secret = "b13136c2-eab7-4910-abc5-5beff83862f6";
export default new Vuex.Store({
  namespaced: true,
  state: {
    selectedLanguage: localStorage.getItem("selectedLanguage") || "ar",
    course: null,
    courseInstructor: null,
    courseReviews: [],
    courseChapters: [],
    user: null,
    accessToken: null,
    refreshToken: null,
    isLoggedIn: sessionStorage.getItem("accessToken") != null,
    token: "",
    categories: [],
    bestsellingCourses: [],
    onlineCourses: [],
    recordCourses: [],
    featuredcourses: [],
    recentcourses: [],
    mycourses: [],
    topDiscountedcourses: [],
    freecourses: [],
    bundle: [],
    MyAllCourses: [],
    testimonials: [],
    instructors: [],
    allinstructors: [],
    blogs: [],
    allBlogs: [],
    blog: null,
    allCategories: [],
    success: null,
    error: null,
    defaultAddress: null,
    welEmail: null,
    defaultPhone: null,
    AboutUs: [],
    loading: false,
    // AddToCart: null,
    cartStatus: null,
    AllTopDiscountedCourses: [],
    AllFreeCourses: [],
    AllFeaturedCourses: [],
    AllRecentCourses: [],
    AllBestSelling: [],
    AllOnline: [],
    AllRecord: [],
    AllBundle: [],
    instructorProfile: [],
    instructorCourses: [],
    FactsSlide: [],
    FactSlider: [],
  },

  mutations: {
    unsetTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.expiresIn = null;
      state.tokenType = null;
      // localStorage.removeItem("accessToken");
      sessionStorage.removeItem("accessToken");
      // localStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");
      // localStorage.removeItem("refreshToken");
      sessionStorage.removeItem("expiresIn");
      // localStorage.removeItem("expiresIn");
      sessionStorage.removeItem("tokenType");
      // localStorage.removeItem("tokenType");
    },
    setLanguage(state, language) {
      state.selectedLanguage = language;
      localStorage.setItem("selectedLanguage", language); // Save the selected language to local storage
    },
    setUser(state, user) {
      state.user = user;
    },
    setCartStatus(state, value) {
      state.cartStatus = value;
    },
    setTokens(state, { accessToken, refreshToken, expiresIn, tokenType }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expiresIn = expiresIn;
      state.tokenType = tokenType;
      // Vue.cookie.set("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      // Vue.cookie.set("refreshToken", refreshToken);

      sessionStorage.setItem("accessToken", accessToken);
      // localStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("expiresIn", expiresIn);
      sessionStorage.setItem("tokenType", tokenType);

      // localStorage.setItem('idToken', idToken);
    },
    setAllBlogs(state, blogs) {
      state.allBlogs = blogs;
    },
    setCourse(state, course) {
      state.course = course;
    },
    setCourseAddedToWishListStatus(state, newState) {
      state.course.in_wishlist = newState;
    },
    setCourseInstructor(state, instructor) {
      state.courseInstructor = instructor;
    },
    setCourseReviews(state, reviews) {
      state.courseReviews = reviews;
    },
    setCourseChapters(state, chapters) {
      state.courseChapters = chapters;
    },
    setBlog(state, blog) {
      state.blog = blog;
    },
    setBlogs(state, blogs) {
      state.blogs = blogs;
    },
    setMyAllCourses(state, payload) {
      state.MyAllCourses = payload;
    },
    setSuccess(state, payload) {
      state.success = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setAboutUs(state, about) {
      state.AboutUs = about;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    set_Error(state, error) {
      state.error = error;
    },
    login(state) {
      state.isLoggedIn = true;
    },
    // logout(state) {
    //   state.isLoggedIn = false;
    // },
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_TESTIMONIALS(state, testimonials) {
      state.testimonials = testimonials;
    },
    SET_INSTRUCTORS(state, instructors) {
      state.instructors = instructors;
    },
    setFacts(state, sliderfacts) {
      state.FactsSlide = sliderfacts;
    },
    setFactSlide(state, slider) {
      state.FactSlider = slider[0];
    },
    SET_ALLINSTRUCTORS(state, instructors) {
      state.allinstructors = instructors;
    },
    setDefaultAddress(state, payload) {
      state.defaultAddress = payload;
    },
    setWelEmail(state, payload) {
      state.welEmail = payload;
    },
    setDefaultPhone(state, payload) {
      state.defaultPhone = payload;
    },
    setAllCategories(state, categories) {
      state.allCategories = categories;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setBestsellingCourses(state, courses) {
      state.bestsellingCourses = courses;
    },
    // online
    setOnlineCourses(state, courses) {
      state.onlineCourses = courses;
    },
    setRecordCourses(state, courses) {
      state.recordCourses = courses;
    },
    // online
    setFeaturedCourses(state, courses) {
      state.featuredcourses = courses;
    },
    setRecentCourses(state, courses) {
      state.recentcourses = courses;
    },
    setMyCourses(state, courses) {
      state.mycourses = courses;
    },
    setTopDiscounted(state, courses) {
      state.topDiscountedcourses = courses;
    },
    setAllTopDiscountedCourses(state, payload) {
      state.AllTopDiscountedCourses = payload;
    },
    setAllBundle(state, payload) {
      state.AllBundle = payload;
    },
    setAllBestSelling(state, payload) {
      state.AllBestSelling = payload;
    },
    setAllOnline(state, payload) {
      state.AllOnline = payload;
    },
    setAllRecord(state, payload) {
      state.AllRecord = payload;
    },
    setAllFeaturedCourses(state, payload) {
      state.AllFeaturedCourses = payload;
    },
    setAllFreeCourses(state, payload) {
      state.AllFreeCourses = payload;
    },
    setAllRecentCourses(state, payload) {
      state.AllRecentCourses = payload;
    },
    setFreeCourses(state, courses) {
      state.freecourses = courses;
    },
    setBundle(state, bundle) {
      state.bundle = bundle;
    },
    setInstructorProfile(state, instructor) {
      state.instructorProfile = instructor;
    },
    setInstructorCourses(state, courses) {
      state.instructorCourses = courses;
    },
    setCourseCartStatus(state, newStatus) {
      state.course.in_cart = newStatus;
    },
    setCourseEnrollmentStatus(state, newStatus) {
      state.course.enrollment_status = newStatus;
    },
  },

  actions: {
    initializeStore({ commit }) {
      const accessToken = sessionStorage.getItem("accessToken");
      const refreshToken = sessionStorage.getItem("refreshToken");
      const expiresIn = sessionStorage.getItem("expiresIn");
      const tokenType = sessionStorage.getItem("tokenType");
      
      const headers = {
        Authorization: `${tokenType} ${accessToken}`,
      };
      if (accessToken && refreshToken && expiresIn && tokenType) {
        commit("setTokens", {
          accessToken,
          refreshToken,
          expiresIn,
          tokenType,
        });
      } else {
        // location.reload()
      }
    },
    changeLocale({ commit }, language) {
      commit("setLanguage", language);
    },
    async login({ commit }, { email, password }) {
      commit("setLoading", true);
      try {
        const response = await fetch(BASE_URL + "/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
          commit("SET_LOGGED_IN", false);
          throw new Error("Login failed");
        } else {
          const data = await response.json();
          commit("setUser", data.user);
          commit("SET_LOGGED_IN", true);
          commit("setTokens", {
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            expiresIn: data.expires_in,
            tokenType: data.token_type,
            // idToken: data.id_token,
          });
          commit("setLoading", false);
          const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours from now
        }
      } catch (error) {
        console.error("Error:", error);
        commit("setLoading", false);
        throw error;
      }
    },

    async logout({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(BASE_URL + "/logout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        });
        if (response.status === 200) {
          commit("SET_LOGGED_IN", false);
          commit("unsetTokens");
          commit("setLoading", false);
        } else {
          console.error("Logout failed:", response);
          commit("setLoading", false);
        }
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
    async fetchAllCategories({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            "/all-categories?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            "&lang=" +
            this.getters.getLangParameter
        );
        if (response.ok) {
          const data = await response.json();
          commit("setAllCategories", data.allcategory);
          commit("setLoading", false);
        } else {
          commit("setLoading", false);
          throw new Error("Error fetching all courses");
        }
      } catch (error) {
        console.error("Error fetching all courses:", error);
      }
    },
    async fetchCourseDetails({ commit, state }, courseId) {
      const token = state.accessToken;
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/course/details?course_id=${courseId}&lang=${this.state.selectedLanguage}&secret=b13136c2-eab7-4910-abc5-5beff83862f6`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Course fetch failed");
        } else {
          const data = await response.json();
          commit("setCourse", data);
          commit("setLoading", false);
        }
      } catch (error) {
        console.error("Error:", error);
        commit("setLoading", false);
        throw error;
      }
    },
    async fetchCourseInstructor({ commit, state }, courseId) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/course/instructor?course_id=${courseId}&lang=${this.state.selectedLanguage}&secret=b13136c2-eab7-4910-abc5-5beff83862f6`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Course fetch failed");
        } else {
          const data = await response.json();
          commit("setCourseInstructor", data.instructor);
          commit("setLoading", false);
        }
      } catch (error) {
        console.error("Error:", error);
        commit("setLoading", false);
        throw error;
      }
    },
    async fetchCourseReviews({ commit, state }, courseId) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/course/reviews?course_id=${courseId}&lang=${this.state.selectedLanguage}&secret=b13136c2-eab7-4910-abc5-5beff83862f6`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Course reviews fetch failed");
        } else {
          const data = await response.json();
          commit("setCourseReviews", data.reviews);
          commit("setLoading", false);
        }
      } catch (error) {
        console.error("Error:", error);
        commit("setLoading", false);
        throw error;
      }
    },
    async fetchCourseChapters({ commit, state }, courseId) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/course/chapters?course_id=${courseId}&lang=${this.state.selectedLanguage}&secret=b13136c2-eab7-4910-abc5-5beff83862f6`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Course reviews fetch failed");
        } else {
          const data = await response.json();
          commit("setCourseChapters", data.chapters);
          commit("setLoading", false);
        }
      } catch (error) {
        console.error("Error:", error);
        commit("setLoading", false);
        throw error;
      }
    },
    async fetchMyAllCourses({ commit, state }) {
      commit("setLoading", true);
      const url =
        BASE_URL +
        "/my-allcourses?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
        this.getters.getLangParameter;
      const AccessToken = state.accessToken;
      const headers = {
        Authorization: `Bearer ${AccessToken}`,
      };

      fetch(url, { headers })
        .then((response) => response.json())
        .then((data) => {
          commit("setMyAllCourses", data.myallcourses);
          commit("setLoading", false);
        })
        .catch((error) => {
          commit("setLoading", false);
          console.error(error);
        });
    },
    async fetchCategories({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/categories?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setCategories", data.category);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchBestsellingCourses({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/best-selling/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          commit("setBestsellingCourses", data.bestselling);
        } else {
          throw new Error("Error fetching bestselling courses");
        }
      } catch (error) {
        console.error("Error fetching bestselling courses:", error);
      }
    },
    // online
    async fetchOnlineCourses({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/online/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          commit("setOnlineCourses", data.onlineCourses);
        } else {
          throw new Error("Error fetching bestselling courses");
        }
      } catch (error) {
        console.error("Error fetching bestselling courses:", error);
      }
    },

    // record
    async fetchRecordCourses({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/record/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          commit("setRecordCourses", data.RecordCourses);
        } else {
          throw new Error("Error fetching bestselling courses");
        }
      } catch (error) {
        console.error("Error fetching bestselling courses:", error);
      }
    },

    //
    async fetchFeaturedCourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/featured/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("setFeaturedCourses", data.featuredcourses);
      } catch (error) {
        console.error("Error fetching featuredcourses courses:", error);
      }
    },
    async fetchRecentCourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/recent/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("setRecentCourses", data.recentcourses);
      } catch (error) {
        console.error("Error fetching recent courses:", error);
      }
    },
    async fetchMyCourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/my-courses?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("setMyCourses", data.mycourses);
      } catch (error) {
        console.error("Error fetching mycourses courses:", error);
      }
    },
    async fetchTopDiscountedcourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/top-discounted/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("setTopDiscounted", data.topDiscountedcourses);
      } catch (error) {
        console.error("Error fetching topDiscountedcourses courses:", error);
      }
    },
    async fetchAllTopDiscountedCourses({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-top-discounted/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllTopDiscountedCourses", data.topDiscountedcourses);
    },
    async fetchAllFreeCourses({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-free/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllFreeCourses", data.allfreecourses);
    },
    // all online
    async fetchAllOnline({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-online/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllOnline", data.allOnlineCourses);
    },
    // all record
    async fetchAllRecord({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-record/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllRecord", data.allRecordCourses);
    },
    async fetchAllFeaturedCourses({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-featured/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllFeaturedCourses", data.featuredcourses);
    },
    async fetchAllRecentCourses({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-recent/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllRecentCourses", data.recentcourses);
    },
    async fetchAllBestSelling({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-best-selling/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllBestSelling", data.allbestselling);
    },
    async fetchAllBundle({ commit }) {
      commit("setLoading", true);
      const response = await fetch(
        BASE_URL +
          "/all-bundle/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      );
      const data = await response.json();
      commit("setLoading", false);
      commit("setAllBundle", data.bundle);
    },
    async fetchInstructorProfile({ commit }, instructorId) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/instructor/get?id=${instructorId}&secret=b13136c2-eab7-4910-abc5-5beff83862f6` +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setInstructorProfile", data.instructor);
        commit("setInstructorCourses", data.courses);
        commit("setLoading", false);
      } catch (error) {
        commit("set_Error", error.message);
        commit("setLoading", false);
      }
    },
    async fetchFreeCourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/free/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("setFreeCourses", data.freecourses);
      } catch (error) {
        console.error("Error fetching freecourses courses:", error);
      }
    },
    async fetchBundleCourses({ commit }) {
      // make the API call to fetch featured courses and commit the mutation with the response
      try {
        const response = await fetch(
          BASE_URL +
            "/bundle/course?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setBundle", data.bundle);
      } catch (error) {
        console.error("Error fetching bundle courses:", error);
      }
    },
    async fetchTestimonials({ commit }) {
      const response = await fetch(
        BASE_URL +
          "/testimonials?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
          this.getters.getLangParameter
      );
      const data = await response.json();
      commit("SET_TESTIMONIALS", data.testimonial);
    },
    async fetchInstructors({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/instructors/home?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("SET_INSTRUCTORS", data.instructors);
      } catch (error) {
        console.error("Error fetching instructors:", error);
      }
    },
    async fetchAllInstructors({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            "/instructors/all?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.state.accessToken}`,
            },
          }
        );
        const data = await response.json();
        commit("SET_ALLINSTRUCTORS", data.instructors);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        console.error("Error fetching all instructors:", error);
      }
    },
    async fetchBlogs({ commit }) {
      try {
        const response = await fetch(
          BASE_URL +
            "/home_blog?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setBlogs", data.blog);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    },
    async fetchFacts({ commit }) {
      try {
        const response = await fetch(
          process.env.VUE_APP_BASE_API_URL +
            "/sliders?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setFacts", data.sliderfacts);
        commit("setFactSlide", data.slider);
      } catch (error) {
        console.error("Error fetching facts:", error);
      }
    },
    async fetchAllBlogs({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            "/blog?&secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setAllBlogs", data.blog);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        console.error("Error fetching all blog:", error);
      }
    },
    async fetchBlog({ commit }, blogId) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            `/blogdetail?id=${blogId}&lang=${this.state.selectedLanguage}&secret=b13136c2-eab7-4910-abc5-5beff83862f6`
        );

        const data = await response.json();
        commit("setBlog", data.blog);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        console.error("Error fetching all blog:", error);
      }
    },
    async fetchAboutUs({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL + "/about-us?" + this.getters.getLangParameter
        );
        const data = await response.json();
        commit("setAboutUs", data.about);
        commit("setLoading", false);
      } catch (error) {
        commit("set_Error", error.message);
        commit("setLoading", false);
        // console.error("Error fetching all blog:", error);
      }
    },
    async contactUs({ commit, state }, contactData) {
      commit("setLoading", true);
      contactData = { ...contactData, reason_id: 1 };
      try {
        const response = await fetch(
          BASE_URL +
            `/contactus?secret=${secret}` +
            this.getters.getLangParameter,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(contactData),
          }
        );

        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        commit("setLoading", false);
        Vue.$toast.open({
          message: "Your message has been sent successfully!",
          type: "success",
        });
        commit("setSuccess", "Your message has been sent successfully!");
      } catch (error) {
        commit("setError", error.message);
        commit("setLoading", false);
      }
    },
    async sendCourseReview({ commit, state }, { courseId, reviewData }) {
      const token = state.accessToken;
      commit("setLoading", true);
      const data = {
        price: reviewData.price,
        learn: reviewData.learn,
        value: reviewData.value,
        review: reviewData.content,
        course_id: courseId,
        secret: "b13136c2-eab7-4910-abc5-5beff83862f6",
        lang: this.state.selectedLanguage,
      };
      try {
        const response = await fetch(BASE_URL + `/review/submit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data);
        } else {
          const data = await response.json();
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Review sent successfully!",
            type: "success",
          });
        }
      } catch (error) {
        commit("setLoading", false);
        commit("setError", error.message);
        Vue.$toast.open({
          message: error.message,
          type: "error",
        });
      }
    },
    async fetchContactInfo({ commit }) {
      commit("setLoading", true);
      try {
        const response = await fetch(
          BASE_URL +
            "/contact/details?secret=b13136c2-eab7-4910-abc5-5beff83862f6" +
            this.getters.getLangParameter
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const data = await response.json();

        commit("setDefaultAddress", data.data.default_address);
        commit("setWelEmail", data.data.wel_email);
        commit("setDefaultPhone", data.data.default_phone);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);

        commit("setError", error.message);
      }
    },

    // new join us
    // new join us
    async sendJoinUs({ commit, state }, { reviewData }) {
      const token = state.accessToken;
      commit("setLoading", true);
      const formData = new FormData();
      formData.append("file", reviewData.file);
      formData.append("image", reviewData.image);
      formData.append("gender", reviewData.gender);
      formData.append("detail", reviewData.detail);
      formData.append("secret", "b13136c2-eab7-4910-abc5-5beff83862f6");
      formData.append("lang", state.selectedLanguage);

      try {
        const response = await fetch(BASE_URL + `/join_us`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.message || "Failed to send the review.");
        } else {
          const data = await response.json();
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Review sent successfully!",
            type: "success",
          });
        }
      } catch (error) {
        commit("setLoading", false);
        commit("setError", error.message);
        Vue.$toast.open({
          message: error.message,
          type: "error",
        });
      }
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
    about: (state) => state.AboutUs,
    instructor: (state) => state.instructorProfile,
    error: (state) => state.error,
    getInstructors: (state) => state.instructors,
    getAllInstructors: (state) => state.allinstructors,
    getBlogs: (state) => state.blogs,
    getBlog: (state) => state.blog,
    getAddToCart: (state) => state.AddToCart,
    getContact: (state) => state.contactus,
    getTestimonials(state) {
      return state.testimonials;
    },
    getCourse: (state) => state.course,
    getLangParameter: (state) => "&lang=" + state.selectedLanguage,
  },
  modules: {
    WishListModule,
    CourseModule,
    CartModule: CartModule,
    CourseContentModule: CourseContentModule,
    BundleModule: BundleModule,
    ProfileModule: ProfileModule,
    InstructorModule: InstructorModule,
    GeneralModule: GeneralModule,
    CourseFilterModule: CourseFilterModule,
    AuthModule: AuthModule,
  },

  // plugins: [createPersistedState()],
});
