<template>
  <div class="relative">
    <!--case it is already followed by the user-->
    <!-- <b-btn
      variant="outline"
      class="button size-50 rounded-full -purple-3 text-light-1 card-action fa fa-user-times text-20"
      v-b-tooltip.hover
      :title="$t('instructor.unfollow')"
      v-if="instructor.following_status"
      @click="checkAuthAndUnfollow(instructor.id)"
    ></b-btn> -->
    <!--case it is not followed by the user-->
    <!-- <b-btn
      variant="outline"
      class="button size-50 rounded-full -purple-3 text-light-1 card-action fa fa-user-plus text-20"
      v-b-tooltip.hover
      :title="$t('instructor.follow')"
      v-if="!instructor.following_status"
      @click="checkAuthAndFollow(instructor.id)"
    ></b-btn> -->
    <RouterLink
      class="d-flex justify-center"
      :to="{
        name: 'InstructorProfileView',
        params: { id: instructor.id },
      }"
    >
      <div class="teamCard -type-1 -teamCard-hover mr-20 ml-20">
        <div class="teamCard__image">
          <img
            v-if="instructor.user_img"
            :src="getInstructorsImageUrl(instructor.user_img)"
            alt="image"
            class="img-width"
          />
          <img
            v-else
            src="@/assets/imgs/profile.png"
            alt="image"
            class="img-width"
          />
        </div>
        <div class="teamCard__content text-center">
          <h4 class="teamCard__title moto-color1_3 animate1">
            {{ instructor.fname + " " + instructor.lname }}
          </h4>
          <p class="visit-text">{{ $t("instructor.visitProfile") }}</p>
          <div class="dez-separator bg-primary"></div>
          <p class="teamCard__text">{{ $t('instructor.name') }}</p>

          <div
            class="row items-center pb-15 x-gap-10 pt-10 justify-content-center"
          >
            <div class="col-auto">
              <div class="d-flex items-center">
                <div
                  class="icon-online-learning text-light-1 text-11 mr-5 ml-5"
                ></div>
                <div class="text-14 lh-12 text-center">
                  <span class="bold-count">{{
                    instructor.course_orders_count + " "
                  }}</span>
                  {{ $t("instructor.student") }}
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="fa fa-user text-light-1 text-11 mr-5 ml-5"></div>
                <div class="text-14 lh-12 text-center">
                  <span class="bold-count">{{
                    instructor.followers_count
                  }}</span>
                  {{ $t("instructor.follower") }}
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="icon-play text-light-1 text-11 mr-5 ml-5"></div>
                <div class="text-14 lh-12 text-center">
                  <span class="bold-count">{{
                    instructor.courses_count + " "
                  }}</span>
                  {{ $t("instructor.course") }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-center mt-20 mb-10">
              <!--case it is already followed by the user-->
              <button
                style="position: relative; z-index: 100"
                class="button -sm -outline-dark-1 text-dark-1"
                v-if="instructor.following_status"
                @click.prevent="checkAuthAndUnfollow(instructor.id)"
              >
                {{ $t("instructor.unfollow") }}
              </button>
              <!--case it is not followed by the user-->
              <button
                style="position: relative; z-index: 100"
                class="button -sm -dark-1 text-white"
                v-if="!instructor.following_status"
                @click.prevent="checkAuthAndFollow(instructor.id)"
              >
                {{ $t("instructor.follow") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  props: {
    instructor: Object,
  },
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  created() {
    EventBus.$on(
      "followedInstructor",
      function (instructorId) {
        if (this.instructor.id == instructorId) {
          this.instructor.following_status = true;
        }
        EventBus.$off("followedInstructor");
      }.bind(this)
    );
    EventBus.$on(
      "unfollowedInstructor",
      function (instructorId) {
        if (this.instructor.id == instructorId) {
          this.instructor.following_status = false;
        }
        EventBus.$off("unfollowedInstructor");
      }.bind(this)
    );
  },
  methods: {
    ...mapActions(["followInstructor", "unfollowInstructor"]),
    getInstructorsImageUrl(user_img) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/user_img/";
      return baseUrl + user_img;
    },
    checkAuthAndFollow() {
      if (this.isLoggedIn) {
        this.followInstructor(this.instructor.id);
      } else {
        this.$router.push({ name: "LogInView" });
      }
    },
    checkAuthAndUnfollow() {
      if (this.isLoggedIn) {
        this.unfollowInstructor(this.instructor.id);
      } else {
        this.$router.push({ name: "LogInView" });
      }
    },
  },
};
</script>
<style scoped>
.card-action {
  position: absolute;
  top: 20px;
  left: 24px;
  z-index: 10;
  font-size: 30px;
}
</style>
