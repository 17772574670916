export default {
  ar: {
    courseContent: {
      yourProgressInCourse: "تقدمك في الدورة",
      hasBeenDone: "تم إنهاء",
      from: "من",
      thereIs: "يوجد",
      announcement: "إعلان",
      assignmentTitle: "عنوان الوظيفة",
      wasDelivered: "تم تسليمها",
      instructorRating: "تقييم المدرب",
      notRatedYet: "لم يتم تقييم الوظيفة بعد",
      uploadAssignmentFile: "رفع ملف وظيفة",
      youHaveDelivered: "لقد قمت برفع",
      assignment: "وظيفة",
      assignmentForm: {
        title: {
          label: "عنوان الوظيفة",
          placeholder: "أدخل عنواناً للوظيفة",
        },
        chapterName: {
          label: "عنوان الفصل",
          placeholder: "اختر أحد الفصول الخاص بالدورة",
        },
        assignmentFile: {
          label: "ملف الوظيفة",
        },
        sendAssignment: "إرسال الوظيفة",
      },
      finishSelectedChapters: "إنهاء الفصول المحددة",
      markAllChaptersAsCompleted: "تحديد جميع الفصول كمنتيهة",
      chapterIsCompleted: "تم إنهاء هذا الفصل",
      selectChapterAsCompleted: "تحديد الفصل كمنتهي",
      downloadLessonContent: "تنزيل محتوى الدرس",
      downloadFile: "تنزيل الملف",
      recentAnnouncement: "الإعلانات الحديثة",
      seeAllAnnouncement: "استعراض كل الإعلانات",
      recentActivity: "النشاطات الحديثة",
      aboutThisCourse: "عن هذه الدورة",
      byTheNumber: "عن الأرقام",
      numberOfStudentEnrolled: "عدد الطلاب المشاركين",
      language: "اللغة",
      lessonsCount: "عدد الدروس",
      startDate: "تاريخ البداية",
      description: "وصف الدورة",
      instructor: "المدرب",
      next: "التالي",
      previous: "السابق",
      finish: "إنهاء",
      chooseAnAnswer: "عليك اختيار إجابة",
      answerAll: "عليك الإجابة على كل الأسئلة",
      perQuestionMarks: "عدد العلامات المخصصة لكل سؤال",
      totalMarks: "العلامة الكلية",
      totalQuestions: "عدد الأسئلة الكلي",
      quizPrice: "تكلفة الاختبار",
      free: "مجاناً",
      tryAgain: "المحاولة مجدداً",
      showReport: "إظهار النتيجة",
      startQuiz: "البدء بالاختبار",
      remainingTime: "الوقت المتبقي",
      timeIsUpMessage: "وقت الاختبار قد انتهى، الرجاء المحاولة مجدداً!",
      printResult: "طباعة النتيجة",
      testResult: "نتيجة اختبار",
      liveClasses: "الصفوف المباشرة",
      starts: "يبدأ",
      ends: "ينتهي",
      timezone: "المنطقة الزمنية",
      duration: "المدة",
      joinLiveClass: "الانضمام للصف المباشر",
      minute: "دقيقة",
      recentQuestions: "الأسئلة الحديثة",
      browseAllQuestions: "استعراض كل الأسئلة",
      showFile: "استعراض الملف",
      previousPapers: "الأوراق السابقة",
      question: "سؤال",
      addNewQuestion: "إضافة سؤال جديد",
      answer: "إجابة",
      addAnswer: "إضافة إجابة",
      reportQuestion: "الإبلاغ عن السؤال",
      deleteQuestion: "حذف السؤال",
      deleteAnswer: "حذف الإجابة",
      theAnswer: "الإجابة",
      questionText: "نص السؤال",
      addQuestion: "إضافة سؤال",
      reportForm: {
        title: {
          label: "عنوان الإبلاغ",
          placeholder: "قم بإدخال عنواناً لبلاغك",
        },
        email: {
          label: "البريد الالكتروني",
          placeholder: "قم بإدخال بريدك الالكتروني ",
        },
        detail: {
          label: "التفاصيل",
        },
        sendReport: "إرسال الإبلاغ",
      },
      completionCertificate: "شهادة إتمام",
      downloadCertificate: "تنزيل ملف الشهادة",
      liveClass:"صف مباشر",
      quiz:"اختبار",
      previousPaper:"ورقة سابقة",
      recentCourse:"جميع الدورات الأخيرة",
      onlineCourse:"كل الكورسات اونلاين",
      recordCourse:"كل الكورسات المسجلة",
      bestCourse:"كل الكورسات الأفضل مبيعا",
      bestFeatured:"كل الكورسات المميزة",
      bestFree:"كل الكورسات المجانية",
      bestMyCourses:"كل كورساتي",
      resent:"اختر نوع",
    },
  },
  en: {
    courseContent: {
      yourProgressInCourse: "Your Progress in the Course",
      hasBeenDone: "Completed",
      from: "From",
      thereIs: "There is",
      announcement: "Announcement",
      assignmentTitle: "Assignment Title",
      wasDelivered: "Submitted",
      instructorRating: "Instructor Rating",
      notRatedYet: "Not Rated Yet",
      uploadAssignmentFile: "Upload Assignment File",
      youHaveDelivered: "You have submitted",
      assignment: "Assignment",
      assignmentForm: {
        title: {
          label: "Assignment Title",
          placeholder: "Enter an assignment title",
        },
        chapterName: {
          label: "Chapter Name",
          placeholder: "Select a chapter from the course",
        },
        assignmentFile: {
          label: "Assignment File",
        },
        sendAssignment: "Submit Assignment",
      },
      finishSelectedChapters: "Finish Selected Chapters",
      markAllChaptersAsCompleted: "Mark All Chapters as Completed",
      chapterIsCompleted: "This chapter is completed",
      selectChapterAsCompleted: "Mark Chapter as Completed",
      downloadLessonContent: "Download Lesson Content",
      downloadFile: "Download File",
      recentAnnouncement: "Recent Announcements",
      seeAllAnnouncement: "See All Announcements",
      recentActivity: "Recent Activity",
      aboutThisCourse: "About This Course",
      byTheNumber: "By the Numbers",
      numberOfStudentEnrolled: "Number of Students Enrolled",
      language: "Language",
      lessonsCount: "Number of Lessons",
      startDate: "Start Date",
      description: "Course Description",
      instructor: "Instructor",
      next: "Next",
      previous: "Previous",
      finish: "Finish",
      chooseAnAnswer: "Choose an answer",
      answerAll: "Answer all questions",
      perQuestionMarks: "Marks per question",
      totalMarks: "Total Marks",
      totalQuestions: "Total Questions",
      quizPrice: "Quiz Price",
      free: "Free",
      tryAgain: "Try Again",
      showReport: "Show Report",
      startQuiz: "Start Quiz",
      remainingTime: "Remaining Time",
      timeIsUpMessage: "The quiz time has expired. Please try again!",
      printResult: "Print Result",
      testResult: "Test Result",
      liveClasses: "Live Classes",
      starts: "Starts",
      ends: "Ends",
      timezone: "Timezone",
      duration: "Duration",
      joinLiveClass: "Join Live Class",
      minute: "minute",
      recentQuestions: "Recent Questions",
      browseAllQuestions: "Browse All Questions",
      showFile: "Show File",
      previousPapers: "Previous Papers",
      question: "Question",
      addNewQuestion: "Add New Question",
      answer: "Answer",
      addAnswer: "Add Answer",
      reportQuestion: "Report Question",
      deleteQuestion: "Delete Question",
      deleteAnswer: "Delete Answer",
      theAnswer: "The Answer",
      questionText: "Question Text",
      addQuestion: "Add Question",
      reportForm: {
        title: {
          label: "Report Title",
          placeholder: "Enter a title for your report",
        },
        email: {
          label: "Email",
          placeholder: "Enter your email address",
        },
        detail: {
          label: "Details",
        },
        sendReport: "Send Report",
      },
      completionCertificate: "Completion Certificate",
      downloadCertificate: "Download Certificate File",
      liveClass:"Live Class",
      quiz:"Quiz",
      previousPaper:"Previous Paper",

      recentCourse:"All recent Courses",
      onlineCourse:"All online Courses",
      recordCourse:"All Record Courses",
      bestCourse:"All Bset Selling Courses",
      bestFeatured:"All Featured Courses",
      bestFree:"All Free Courses Courses",
      bestMyCourses:"All My Courses Courses",
      resent:"ِAll recent",


    },
  },
};
