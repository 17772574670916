export default {
  ar: {
    signUp: "إنشاء حساب جديد",
    signUpIntro:
      "لتجربة مميزة وفريدة معنا، قم بإنشاء حساب جديد وابدأ رحلتك في عالم التعليم الافتراضي. أملأ النموذج التالي ببياناتك الشخصية واحتفظ بمعلومات الدخول بأمان.",
    alreadyHaveAnAccount: "هل لديك حساب",
    goLogin: "قم بتسجيل الدخول!",
    signUpForm: {
      username: {
        label: "اسم المستخدم",
        placeholder: "اسم المستخدم",
      },
      email: {
        label: "البريد الالكتروني",
        placeholder: "البريد الالكتروني",
      },
      mobileNumber: {
        label: "رقم الهاتف",
        placeholder: "رقم الهاتف",
      },
      password: {
        label: "كلمة السر",
        placeholder: "كلمة السر",
      },
      passwordConfirmation: {
        label: "تأكيد كلمة السر",
        placeholder: "تأكيد كلمة السر",
      },
      formAction: "إنشاء حساب",
      generalErrorMessage: ".حدث خطأ ما أثناء عملية التسجيل",
    },
  },
  en: {
    signUp: "Sign Up",
    signUpIntro:
      "For a unique and exceptional experience with us, create a new account and start your journey in the world of virtual education. Fill out the following form with your personal information and keep your login information secure.",
    alreadyHaveAnAccount: "Already have an account?",
    goLogin: "Log in!",
    signUpForm: {
      username: {
        label: "Username",
        placeholder: "Username",
      },
      email: {
        label: "Email",
        placeholder: "Email",
      },
      mobileNumber: {
        label: "Mobile Number",
        placeholder: "Mobile Number",
      },
      password: {
        label: "Password",
        placeholder: "Password",
      },
      passwordConfirmation: {
        label: "Confirm Password",
        placeholder: "Confirm Password",
      },
      formAction: "Create Account",
      generalErrorMessage: "An error occurred during the registration process.",
    },
  },
};
