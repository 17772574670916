<template>
    <section class="layout-pt-lg layout-pb-lg">
        <div class="container">
            <div class="row y-gap-20 justify-between items-center">
                <div class="col-lg-9">
                    <div class="sectionTitle">
                        <h2 class="sectionTitle__title">{{ $t('learnFromBestInstructors') }}</h2>

                        <p class="sectionTitle__text" v-html="$t('bestInstructorsDescription')"></p>
                    </div>
                </div>

                <div class="col-auto">
                    <RouterLink
                        :to="{ name: 'InstructorsView' }"
                        class="button btn-all-view -icon -purple-3 text-purple-1"
                    >
                        {{ $t("instructor.viewAll") }}
                        <i
                            class="icon-arrow-top-right text-13 ml-10 mr-10"
                        ></i>
                    </RouterLink>
                </div>
            </div>
            <div class="row y-gap-30 pt-50">
                <Carousel
                    v-if="getInstructors.length > 0"
                    :responsive="responsive"
                    class="mt-20 mb-20"
                    :rtl="$i18n.locale == 'ar' ? true : false"
                    :slidesToShow="4"
                    :pauseOnFocus="true"
                    :pauseOnDotsHover="true"
                    :infinite="true"
                    :slidesToScroll="2"
                    :focusOnSelect="false"
                    :speed="500"
                    :autoplay="true"
                    :autoplaySpeed="3000"
                    :touchMove="false"
                >
                    <InstructorCard
                        v-for="instructor in getInstructors"
                        :key="instructor.id"
                        :instructor="instructor"
                    />
                </Carousel>
            </div>

            <div class="row justify-center text-center pt-60 lg:pt-40">
                <div class="col-auto">
                    <p class="lh-1">
                        {{ $t("instructor.wantToHelpPeople") }}
                        <RouterLink
                            :to="{ name: 'JoinAsInstructor' }"
                            class="text-purple-1 underline"
                        >{{ $t("instructor.joinAsInstructor") }}</RouterLink>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Carousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { RouterLink } from "vue-router";
import { mapGetters, mapActions } from "vuex";
import InstructorCard from "../shared-component/InstructorCard.vue";
export default {
    name: "BestInstructor",
    data() {
        return {};
    },
    components: {
        Carousel,
        RouterLink,
        InstructorCard,
    },
    computed: {
        ...mapGetters(["getInstructors"]),
        responsive() {
            return [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 998,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 0,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ];
        },
    },
    created() {
        this.fetchInstructors();
    },
    methods: {
        ...mapActions(["fetchInstructors"]),
    },
};
</script>
<style scoped>
.ltr {
    direction: ltr !important;
}
</style>
