import httpClient from "../client/httpClient";
import Vue from "vue";

const BundleModule = {
  state: () => ({
    bundle:null,
  }),
  mutations: {
    setBundle(state,bundle){
        state.bundle=bundle;
    },
  },
  actions: {
    async fetchBundle({ commit }, bundleId) {
      commit("setLoading", true);
      await httpClient
        .get("/bundle/details", { params: { id: bundleId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setBundle", response.data.bundle[0]);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    
    async enrollToBundle({ commit }, bundleId) {
      commit("setLoading", true);
      await httpClient
        .post(`/bundle/free/enroll/${bundleId}`)
        .then((response) => {
          commit("setLoading", false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },

  
  },
};
export default BundleModule;
