export default {
  ar: {
    messages: {
      identicalPassword: "كلمة السر وتأكيدها غير متطابق",
      requestSent: "تم الإرسال بنجاح",
      passwordResetSuccess: "تم تغيير كلمة السر بنجاح",
    },
  },
  en: {
    messages: {
      identicalPassword: "Password and its confirmation do not match",
      requestSent: "Request sent successfully",
      passwordResetSuccess: "Password changed successfully",
    },
  },
};
