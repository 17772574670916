import axios from "axios";

const baseURL = process.env.VUE_APP_BASE_API_URL;

const httpClient = axios.create({
  baseURL,
  responseType: "json",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
  },
  params: {
    secret: "b13136c2-eab7-4910-abc5-5beff83862f6",
    lang: localStorage.getItem("selectedLanguage") ?? "ar",
  },
});

httpClient.interceptors.request.use(function (config) {
  const token =sessionStorage.getItem("accessToken");
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
});


export default httpClient;