export default {
  ar: {
    cart: {
      cart: "السلة الشرائية",
      thereIs: "يوجد",
      courseInCart: "دورة في السلة الشرائية",
      emptyCart: "السلة الشرائية فارغة،  أضف بعض الدورات إلى السلة!",
      item: "العنصر",
      price: "السعر",
      removeAll: "إزالة الجميع",
      cartTotals: "التكاليف الخاصة بالسلة الشرائية",
      totalPrice: "السعر الإجمالي",
      offerDiscount: "خصم العرض",
      couponDiscount: "خصم القسائم",
      discountPercent: "نسبة الخصم",
      cartTotal: "السعر الكلي",
      proceedToCheckout: "الإكمال إلى عملية الدفع",
      couponCode: "رمز القسيمة",
      inputCouponCode: "أدخل رمز القسيمة",
      applyCoupon: "تطبيق القسيمة",
      checkout: "عملية الدفع",
      doCheckout: "القيام بعملية الدفع",
      discountAmount: "مقدار الخصم",
      removeAllCoupons: "إزالة جميع القسائم",
      transactionId: "الرقم المميز للوصل",
      inputTransactionId: "أدخل الرقم المميز للوصل",
      choosePaymentMethod: "اختر طريقة الدفع",
      hasBeenChosen: "تم اختيار",
      appliedCoupons: "قسيمة مطبقة",
      addToCart: "أضف للسلة الشرائية",
      removeFromCart: "إزالة من السلة الشرائية",
      buyNow: "اشتري الآن",
      enrollNow: "اشترك الآن",
      goToCourse: "الذهاب إلى محتوى الدورة",
      purchased: "تم الشراء",
    },
  },

  en: {
    cart: {
      cart: "Shopping Cart",
      thereIs: "There is",
      courseInCart: "course in the shopping cart",
      emptyCart: "The shopping cart is empty. Add some courses to the cart!",
      item: "Item",
      price: "Price",
      removeAll: "Remove All",
      cartTotals: "Shopping Cart Totals",
      totalPrice: "Total Price",
      offerDiscount: "Offer Discount",
      couponDiscount: "Coupon Discount",
      discountPercent: "Discount Percentage",
      cartTotal: "Cart Total",
      proceedToCheckout: "Proceed to Checkout",
      couponCode: "Coupon Code",
      inputCouponCode: "Enter coupon code",
      applyCoupon: "Apply Coupon",
      checkout: "Checkout",
      doCheckout: "Proceed to Checkout",
      discountAmount: "Discount Amount",
      removeAllCoupons: "Remove All Coupons",
      transactionId: "Transaction ID",
      inputTransactionId: "Enter transaction ID",
      choosePaymentMethod: "Choose Payment Method",
      hasBeenChosen: "has been chosen",
      appliedCoupons: "Applied Coupons",
      addToCart: "Add to Cart",
      removeFromCart: "Remove from Cart",
      buyNow: "Buy Now",
      enrollNow: "Enroll Now",
      goToCourse: "Go to Course Content",
      purchased: "Purchased",
    },
  },
};
