<template>
  <div class="relative">
    <!--case it is in wishlist-->
    <b-btn
      variant="outline"
      class="card-love-action fa fa-heart text-28 red-icon"
      v-b-tooltip.hover
      :title="$t('course.removeFromWishlist')"
      v-if="course.in_wishlist"
      @click="checkAuthAndRemoveFromWishlist(course.id)"
    ></b-btn>
    <!--case it is not in wishlist-->
    <b-btn
      variant="outline"
      class="card-love-action fa fa-heart-o text-28"
      v-b-tooltip.hover
      :title="$t('course.addToWishlist')"
      v-if="!course.in_wishlist"
      @click="checkAuthAndAddToWishlist(course.id)"
    ></b-btn>
      <b-badge
      class="featured-badge"
      v-if="course.featured == '1'"
      pill
      variant="danger"
      >مميزة</b-badge
    >
    <RouterLink
      :to="{ name: 'course-details', params: { id: course.id } }"
      class="coursesCard -type-1 rounded-8 shadow-3 bg-white"
    >
      <div class="relative">
        <div
          class="coursesCard__image overflow-hidden rounded-top-8 img-container"
        >
          <img
            class="w-1/1"
            :src="getPreviewImagePath(course?.preview_image)"

            alt="image"
          />
          <div class="coursesCard__image_overlay rounded-top-8"></div>
        </div>
        <div
          class="d-flex justify-between py-10 px-10 absolute-full-center z-3"
        >
          <div v-for="tag in course?.course_tags" :key="tag">
            <div class="badge px-15 py-8 text-11 bg-green-1 text-white fw-400">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>

      <div class="h-100 pt-20 pb-15 px-30">
        <div class="d-flex items-center">
          <div class="text-14 lh-1 text-yellow-1 mr-10 ml-10">
            {{ course.total_rating }}
          </div>
          <div class="d-flex x-gap-5 items-center">
            <b-form-rating
              :locale="$i18n.locale"
              variant="warning"
              v-model="course.total_rating"
              readonly
              no-border
            ></b-form-rating>
          </div>
          <div class="text-13 lh-1 ml-10 mr-10">
            % {{ course.total_rating_percent.toFixed(2) }}
          </div>
        </div>

        <div class="text-17 lh-15 fw-500 text-dark-1 mt-10">
          {{ course?.title }}
        </div>

        <div class="d-flex x-gap-10 items-center pt-10">
          <div class="d-flex items-center">
            <div class="mr-8 ml-8">
              <div class="icon-video-file"></div>
            </div>
            <div class="text-14 lh-1">{{ course?.chapter_count }} {{ $t("course.chapter") }}</div>
          </div>

          <div class="d-flex items-center">
            <div class="mr-8 ml-8">
              <div class="icon-clock-2"></div>
            </div>
            <div class="text-14 lh-1">
              {{ Math.ceil(course?.courseclass_sum_duration / 60) }} {{ $t("course.min") }}
            </div>
          </div>

          <div class="d-flex items-center">
            <div class="mr-8 ml-8">
              <div class="icon-bar-chart-2"></div>
            </div>
            <div class="text-14 lh-1">{{ course?.level_tags }}</div>
          </div>
        </div>

        <div class="coursesCard-footer">
          <div class="coursesCard-footer__author">
            <img v-if="course?.user?.user_img" :src="getUserImageUrl(course.user.user_img)" alt="image" />
            <img v-else src="@/assets/imgs/profile.png" alt="image" />
            <div class="mr-10 ml-10">
              {{ course?.user?.fname + " " + course?.user?.lname }}
            </div>
          </div>

          <div>
            <div
              class="text-24 lh-1 text-dark-1 fw-500"
              v-if="course?.price && course?.discount_price"
            >
              {{ course?.discount_price }} $
            </div>
            <div
              class="text-24 lh-1 text-dark-1 fw-500"
              v-if="course?.price && !course?.discount_price"
            >
              {{ course?.price }} $
            </div>
            <div class="text-24 lh-1 text-dark-1 fw-500" v-if="!course?.price">
              {{ $t("course.free") }}
            </div>
            <div class="lh-1 line-through" v-if="course?.discount_price">
              {{ course?.price }} $
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  props: {
    course: Object,
  },
  
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  created(){
    EventBus.$on(
      "courseAddedToWishlist",
      function (courseId) {
        if(this.course.id==courseId){
          this.course.in_wishlist=true;
        }
        EventBus.$off("courseAddedToWishlist");
      }.bind(this)
    );
    EventBus.$on(
      "courseRemovedFromWishlist",
      function (courseId) {
        if(this.course.id==courseId){
          this.course.in_wishlist=false;
        }
        EventBus.$off("courseRemovedFromWishlist");
      }.bind(this)
    );
  },
  methods: {
    goToCourseDetails(courseId) {
      this.$router.push({ name: "course-details", params: { id: courseId } });
    },
    getPreviewImagePath(image) {
      return process.env.VUE_APP_BASE_URL + "/images/course/" + image;
    },
    getUserImageUrl(user_img) {
      const baseUrl = process.env.VUE_APP_BASE_URL + "/public/images/user_img/";
      return baseUrl + user_img;
    },
    ...mapActions(["addToWishlist", "removeFromWishlist"]),
    checkAuthAndAddToWishlist(courseId) {
      if (this.isLoggedIn) {
        this.addToWishlist(courseId);
      } else {
        this.$router.push({ name: "LogInView" });
      }
    },
    checkAuthAndRemoveFromWishlist(courseId) {
      if (this.isLoggedIn) {
        this.removeFromWishlist(courseId);
      } else {
        this.$router.push({ name: "LogInView" });
      }
    },
  },
  components: { RouterLink },
};
</script>

<style scoped>
.card-love-action {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 10;
  font-size: 30px;
}
.featured-badge {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 10;
  font-size: 16px;
}
.red-icon {
  color: #dc3545;
}
.img-container {
  width: 100%; /* Adjust the width as needed */
  height: auto;
  max-width: 100%;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
