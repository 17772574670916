import httpClient from "../client/httpClient";
import Vue from "vue";

const ProfileModule = {
  state: () => ({
    user: null,
    notifications: [],
    unreadNotificationsCount: 0,
    myCourses: [],
    myWishlist: [],
    myPurchaseHistory: [],
    countries:[],
    states:[],
    cities:[],
    invoice:{},
  }),
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setUnreadNotificationsCount(state, count) {
      state.unreadNotificationsCount = count;
    },
    setMyCourses(state, myCourses) {
      state.myCourses = myCourses;
    },
    setMyWishlist(state, myWishlist) {
      state.myWishlist = myWishlist;
    },
    setMyPurchaseHistory(state, myPurchaseHistory) {
      state.myPurchaseHistory = myPurchaseHistory;
    },
    setCountries(state,countries){
      state.countries=countries;
    },
    setStates(state,states){
      state.states=states;
    },
    setCities(state,cities){
      state.cities=cities;
    },
    setInvoice(state,invoice){
      state.invoice=invoice;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/show/profile")
        .then((response) => {
          commit("setLoading", false);
          commit("setUser", response.data.user);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchEnrolledCourses({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/my-allcourses")
        .then((response) => {
          commit("setLoading", false);
          commit("setMyCourses", response.data.myallcourses);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchNotifications({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/user-notifications")
        .then((response) => {
          commit("setLoading", false);
          commit("setNotifications", response.data.notifications);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchUnreadNotificationsCount({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/unread-notifications-count")
        .then((response) => {
          console.log(response);
          commit("setLoading", false);
          commit("setUnreadNotificationsCount", response.data.notifications_count);
          return Promise.resolve(response);

        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchMyWishlist({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/wishlist/course")
        .then((response) => {
          commit("setLoading", false);
          commit("setMyWishlist", response.data.wishcourses);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchPurchaseHistory({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/purchase/history")
        .then((response) => {
          commit("setLoading", false);
          commit("setMyPurchaseHistory", response.data.orderhistory);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitReadAllNotifications({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/edit-notifications-status")
        .then((response) => {
          commit("setLoading", false);
          this.fetchNotifications();
          this.fetchUnreadNotificationsCount();
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitDeleteAllNotifications({ commit }) {
      commit("setLoading", true);
      await httpClient
        .post("/delete-all-notification")
        .then((response) => {
          commit("setLoading", false);
          this.fetchNotifications();
          this.fetchUnreadNotificationsCount();
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitDeleteNotification({ commit },notificationId) {
      commit("setLoading", true);
      await httpClient
        .post("/delete-notification",{id:notificationId})
        .then((response) => {
          commit("setLoading", false);
          this.fetchNotifications();
          this.fetchUnreadNotificationsCount();
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchCountries({ commit }) {
      commit("setLoading", true);
      await httpClient
        .get("/countries")
        .then((response) => {
          commit("setLoading", false);
          commit("setCountries", response.data.countries);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchStates({ commit },countryId) {
      commit("setLoading", true);
      await httpClient
        .get("/states",{ params: { country_id: countryId } })
        .then((response) => {
          commit("setLoading", false);
          commit("setStates", response.data.states);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async fetchCities({ commit },stateID) {
      commit("setLoading", true);
      await httpClient
        .get("/cities",{ params: { state_id: stateID } })
        .then((response) => {
          commit("setLoading", false);
          commit("setCities", response.data.cities);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },
    async submitProfile({commit},profileData){
      commit("setLoading", true);
      await httpClient
        .post("/update/profile",profileData,{
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: "Updated successfully!",
            type: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);

        });
    },
    async fetchInvoice({ commit },invoiceId) {
      commit("setLoading", true);
      await httpClient
        .get(`/invoice/${invoiceId}`)
        .then((response) => {
          commit("setLoading", false);
          commit("setInvoice", response.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          commit("setLoading", false);
          Vue.$toast.open({
            message: error?.response?.data ?? "something wrong happened",
            type: "error",
          });
          console.error(error);
          return Promise.reject(error);
        });
    },

  },
};
export default ProfileModule;
