export default {
  ar: {
    profile: {
      profile: "الملف الشخصي",
      notifications: "إشعاراتي",
      myCourses: "دوراتي",
      myWishlist: "قائمة المحفوظات",
      myLeaderBoard: "لوحة التقدم",
      myPurchaseHistory: "عمليات الدفع السابقة",
      fname: {
        label: "الاسم الأول",
        placeholder: "قم بإدخال اسمك الأول",
      },
      lname: {
        label: "الاسم الأخير",
        placeholder: "قم بإدخال اسمك الأخير",
      },
      email: {
        label: "البريد الالكتروني",
        placeholder: "قم بإدخال بريدك الالكتروني ",
      },
      mobile: {
        label: "رقم الهاتف المحمول",
        placeholder: "قم بإدخال رقم هاتفك المحمول  ",
      },
      address: {
        label: "العنوان",
        placeholder: "قم بإدخال عنوان إقامتك",
      },
      country: {
        label: "البلد",
        placeholder: "قم باختيار بلدك",
      },
      city: {
        label: "المدينة",
        placeholder: "قم باختيار مدينتك",
      },
      state: {
        label: "المنطقة",
        placeholder: "قم باختيار منطقتك",
      },
      aboutYou: "لمحة عنك",
      changePassword: "تغيير كلمة السر",
      currentPassword: {
        label: "كلمة السر الحالية",
        placeholder: "كلمة السر الحالية",
      },
      newPassword: {
        label: "كلمة السر الجديدة",
        placeholder: "كلمة السر الجديدة",
      },
      newPasswordConfirmation: {
        label: "تأكيد كلمة السر الجديدة",
        placeholder: "تأكيد كلمة السر الجديدة",
      },
      saveChanges: "حفظ التغييرات",
      thereIs: "يوجد",
      newNotification: "إشعار جديد",
      readAll: "قراءة الكل",
      deleteAll: "حذف الكل",
      deleteNotification: "حذف الإشعار",
      enrolledCourse: "دورة تم الاشتراك بها",
      finishedCourse: "دورة تم إنهاؤها",
      rightQuiz: "اختبار صحيح",
      savedCourse: "دورة تم الاحتفاظ بها",
      payment: "عملية دفع",
      purchaseHistory: "عمليات الشراء",
      enrolledOn: "تاريخ الاشتراك",
      enrollExpiration: "تاريخ نهاية الاشتراك",
      paymentMethod: "طريقة الدفع",
      totalPrice: "المبلغ الكلي",
      paymentStatus: "حالة عملية الدفع",
      actions: "الإجراءات",
      received: "تم الاستلام",
      notReceived: "لم يتم الاستلام",
      showDetails: "عرض التفاصيل",
      totalPayments: "مجموع عمليات الدفع",
      invoice: "فاتورة",
      from: "المرسل",
      to: "المستلم",
      orderId: "الرقم المميز للطلب",
      transactionId: "الرقم المميز للمناقلة",
      currency: "العملة",
      downloadInvoice: "تنزيل الفاتورة",
      courses: "الدورات",
      instructor: "المدرب",
    },
  },
  en: {
    profile: {
      profile: "Profile",
      notifications: "Notifications",
      myCourses: "My Courses",
      myWishlist: "My Wishlist",
      myLeaderBoard: "My Leaderboard",
      myPurchaseHistory: "My Purchase History",
      fname: {
        label: "First Name",
        placeholder: "Enter your first name",
      },
      lname: {
        label: "Last Name",
        placeholder: "Enter your last name",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
      },
      mobile: {
        label: "Mobile Number",
        placeholder: "Enter your mobile number",
      },
      address: {
        label: "Address",
        placeholder: "Enter your residential address",
      },
      country: {
        label: "Country",
        placeholder: "Select your country",
      },
      city: {
        label: "City",
        placeholder: "Select your city",
      },
      state: {
        label: "State",
        placeholder: "Select your state",
      },
      aboutYou: "About You",
      changePassword: "Change Password",
      currentPassword: {
        label: "Current Password",
        placeholder: "Current Password",
      },
      newPassword: {
        label: "New Password",
        placeholder: "New Password",
      },
      newPasswordConfirmation: {
        label: "Confirm New Password",
        placeholder: "Confirm New Password",
      },
      saveChanges: "Save Changes",
      thereIs: "There is",
      newNotification: "New Notification",
      readAll: "Read All",
      deleteAll: "Delete All",
      deleteNotification: "Delete Notification",
      enrolledCourse: "Enrolled Course",
      finishedCourse: "Finished Course",
      rightQuiz: "Correct Quiz",
      savedCourse: "Saved Course",
      payment: "Payment",
      purchaseHistory: "Purchase History",
      enrolledOn: "Enrolled On",
      enrollExpiration: "Enrollment Expiration",
      paymentMethod: "Payment Method",
      totalPrice: "Total Price",
      paymentStatus: "Payment Status",
      actions: "Actions",
      received: "Received",
      notReceived: "Not Received",
      showDetails: "Show Details",
      totalPayments: "Total Payments",
      invoice: "Invoice",
      from: "From",
      to: "To",
      orderId: "Order ID",
      transactionId: "Transaction ID",
      currency: "Currency",
      downloadInvoice: "Download Invoice",
      courses: "Courses",
      instructor: "Instructor",
    },
  },
};
