export default {
  ar: {
    course: {
      course: "دورة",
      hour: "ساعة",
      min: "دقيقة",
      hours: "ساعات",
      min: "دقيقة",
      month: "شهر",
      day: "يوم",
      free: "مجاناً",
      tags: "العناوين",
      totalHoursCount: "عدد الدقائق الكلي",
      skillLevel: "درجة المهارات",
      certificate: "الشهادة",
      theLanguage: "اللغة",
      available: "متاح",
      unavailable: "غير متاح",
      removeFromWishlist: "إزالة من المفضلة",
      addToWishlist: "إضافة إلى المفضلة",
      reportCourse: "الإبلاغ عن الدورة",
      youMayAlsoLike: "قد يعجبك أيضاً",
      coursesYouMayAlsoLike: "بعض الدورات التي قد تنال إعجابك",
      aboutTheCourse: "عن الدورة",
      category: "التصنيف",
      includes: "تتضمن",
      chapters: "فصول",
      chapter: "فصل",
      theChapters: "الفصول",
      theLessons: "الدروس",
      lesson: "درس",
      theQuizzes: "الاختبارات",
      "Q&A": "أسئلة وأجوبة",
      announcements: "الإعلانات",
      assignments: "الوظائف",
      theQuizzes: "الاختبارات",
      liveClasses: "الصفوف الحية",
      previousPapers: "الأوراق السابقة",
      quiz: "اختبار",
      expandAll: "توسعة الجميع",
      collapseAll: "إخفاء الجميع",
      MegaByte: "ميغا بايت",
      quizzes: "اختبارات",
      overview: "لمحة",
      courseContent: "محتوى الدورة",
      requirements: "المتطلبات",
      whatToLearn: "ماذا ستتعلم",
      instructor: "المدرب",
      reviews: "التقييمات",
      startsAt: "يبدأ في ",
      endAt: "ينتهي في ",
      lastUpdate: "آخر تحديث",
      goToInstructorProfile: "الذهاب إلى ملف  للمدرب",
      reportCourse: "الإبلاغ عن الدورة",
      reviewForm: {
        title: "أرسل تقييماً ",
        priceRate: {
          label: "تقييم السعر",
        },
        valueRate: {
          label: "تقييم القيمة",
        },
        learnRate: {
          label: "تقييم التعليم",
        },
        content: {
          label: "محتوى التقييم",
          placeholder: "أدخل تفاصيل محتوى التقييم",
        },
        reviewAction: "إرسال التقييم",
      },
      reportForm: {
        email: {
          label: "البريد الالكتروني",
          placeholder: "أدخل بريدك الالكتروني",
        },
        title: {
          label: "عنوان الإبلاغ",
          placeholder: "أدخل عنواناً لبلاغك",
        },
        detail: {
          label: "التفاصيل",
          placeholder: "أدخل تفاصيل لبلاغك",
        },
        reportAction: "أرسل الإبلاغ",
      },
      reportReview: "الإبلاغ عن التقييم",
      details: "التفاصيل",
      includedCourses: "الدورات المضمنة",
      showDetails: "عرض التفاصيل",
    },
  },
  en: {
    course: {
      course: "Course",
      hour: "Hour",
      min: "minutes",
      hours: "Hours",
      month: "Month",
      min: "Minutes",
      day: "Day",
      free: "Free",
      tags: "Tags",
      totalHoursCount: "Total Minutes Count",
      skillLevel: "Skill Level",
      certificate: "Certificate",
      theLanguage: "Language",
      available: "Available",
      unavailable: "Unavailable",
      removeFromWishlist: "Remove from Wishlist",
      addToWishlist: "Add to Wishlist",
      reportCourse: "Report Course",
      youMayAlsoLike: "You May Also Like",
      coursesYouMayAlsoLike: "Courses You May Also Like",
      aboutTheCourse: "About the Course",
      category: "Category",
      includes: "Includes",
      chapters: "Chapters",
      chapter: "Chapter",
      theChapters: "The Chapters",
      theLessons: "The Lessons",
      lesson: "Lesson",
      theQuizzes: "The Quizzes",
      "Q&A": "Q&A",
      announcements: "Announcements",
      assignments: "Assignments",
      liveClasses: "Live Classes",
      previousPapers: "Previous Papers",
      quiz: "Quiz",
      expandAll: "Expand All",
      collapseAll: "Collapse All",
      MegaByte: "Megabyte",
      quizzes: "Quizzes",
      overview: "Overview",
      courseContent: "Course Content",
      requirements: "Requirements",
      whatToLearn: "What You Will Learn",
      instructor: "Instructor",
      reviews: "Reviews",
      startsAt: "Starts at",
      endAt: "Ends at",
      lastUpdate: "Last Update",
      goToInstructorProfile: "Go to Instructor Profile",
      reportCourse: "Report Course",
      reviewForm: {
        title: "Submit a Review",
        priceRate: {
          label: "Price Rating",
        },
        valueRate: {
          label: "Value Rating",
        },
        learnRate: {
          label: "Learning Rating",
        },
        content: {
          label: "Review Content",
          placeholder: "Enter review content details",
        },
        reviewAction: "Submit Review",
      },
      reportForm: {
        email: {
          label: "Email",
          placeholder: "Enter your email address",
        },
        title: {
          label: "Report Title",
          placeholder: "Enter a title for your report",
        },
        detail: {
          label: "Details",
          placeholder: "Enter report details",
        },
        reportAction: "Send Report",
      },
      reportReview: "Report Review",
      details: "Details",
      includedCourses: "Included Courses",
      showDetails: "Show Details",
    },
  },
};
